<template>
  <div class="card-branco">
    <div class="d-flex justify-content-between align-items-center pb-4">
      <h1>PTAMs</h1>
      <div class="d-flex gap-3">
        <router-link :to="{ name: 'Novo Ptams' }" class="btnRoxo">
          Novo PTAMs
        </router-link>
        <router-link :to="{ name: 'Ptams rascunho' }" class="btnBordaRoxo">
          Rascunhos
        </router-link>
      </div>
    </div>
    <strong style="color: var(--roxo)">
      Filtrar por:
    </strong>
    <form class="mb-3 d-flex gap-3 flex-wrap align-items-end" @submit.prevent="atualizarUrl">
      <div class="d-flex gap-3 align-items-center">
        <div>
          <label>Filtrar por:</label>
          <input id="id" v-model.trim="idBusca" placeholder="ID" class="inputtext " name="id" type="text">
        </div>
      </div>
      <div class="d-flex gap-3">
        <button @click="limparFiltro" type="button" class="btnLabel">
          <font-awesome-icon icon="trash" />
          Limpar
        </button>
        <button class="btnAmarelo">
          Pesquisar
        </button>
      </div>
    </form>
    <div class="tabela">
      <table class="w-100">
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <thead>
          <tr>
            <th>ID</th>
            <th>Endereço</th>
            <th>Tipo de imóvel</th>
            <th>Negócio</th>
            <th>Data</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in lista" :key="item.id">
            <td>{{ item.id }}</td>
            <td>{{ item.endereco }}</td>
            <td>{{ item.tipo_imovel }}</td>
            <td>{{ item.tipo_negocio }}</td>
            <td>{{ dateToField(item.created_at) }}</td>
            <td class="tdBtnAcao">
              <router-link :to="{
                name: 'Editar Editar',
                params: { ptamsId: item.id_imovel, laudoId: item.id }
              }" class="icon">
                <font-awesome-icon icon="pen-to-square" />
              </router-link>
            </td>
          </tr>
          <tr v-if="chamadasPendentes.lista">
            <td colspan="3">
              <font-awesome-icon icon="spinner" class="spinner mt-3" />
              Carregando
            </td>
          </tr>
          <tr v-else-if="erro">
            <td colspan="3">
              Erro: {{ erro }}
            </td>
          </tr>
          <tr v-else-if="!lista.length">
            <td colspan="3">
              Nenhum resultado encontrado.
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="d-flex gap-2 justify-content-center mt-5">
      <button v-for="(link, index) in paginacao.links.slice(1, -1)" :key="index" class="paginacao-btn"
        :disabled="!link.url" :class="link.active ? 'paginacaoAtiva' : ''" @click="mudarPagina(link)">
        {{ link.label }}
      </button>
    </div>
  </div>
</template>

<script setup>
import dateToField from '@/helpers/dateToFiel';
import { usePtamsStore } from '@/store/ptams.store';
import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const ptamsStore = usePtamsStore()
const { lista, chamadasPendentes, erro, paginacao } = storeToRefs(ptamsStore);

const route = useRoute();
const router = useRouter();

const idBusca = ref(route.query.id)

const consulta = {};
function atualizarUrl() {

  if (idBusca.value) {
    consulta.id = idBusca.value;
  }

  router.push({
    query: consulta,
  });
}

watch([
  () => route.query.id,
], () => {
  ptamsStore.$reset();
  ptamsStore.buscarTudo(consulta);
}, { immediate: true });

function limparFiltro() {
  idBusca.value = '';
  router.push({
    query: {}
  });
  ptamsStore.$reset();
  ptamsStore.buscarTudo({});
}

async function mudarPagina(link) {
  if (link) {
    await ptamsStore.mudarPagina(link.label)
  }
}
</script>
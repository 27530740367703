<template>
  <div>
    <form @submit.prevent="onSubmitInformacaoImoveis">
      <div class="d-flex gap-3 w-100 mb-2">
        <div class="mb-3 w-100">
          <LabelFromYup name="tipo_imovel" :schema="schema" :required="true" />
          <Field v-model="tipo_imovel" name="tipo_imovel" as="select" class="inputtext"
            :class="{ 'error': errors.tipo_imovel }">
            <option value="">
              Selecionar
            </option>
            <option v-for="item in Object.values(tipoImovel)" :key="item.valor" :value="item.valor">
              {{ item.valor }}
            </option>
          </Field>
          <ErrorMessage name="tipo_imovel" class="error-msg" />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup name="tipo_regiao" :schema="schema" :required="true" />
          <Field v-model="tipo_regiao" name="tipo_regiao" as="select" class="inputtext"
            :class="{ 'error': errors.tipo_regiao }">
            <option value="">
              Selecionar
            </option>
            <option v-for="item in Object.values(urbanoRural)" :key="item.valor" :value="item.valor">
              {{ item.nome }}
            </option>
          </Field>
          <ErrorMessage name="tipo_regiao" class="error-msg" />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup name="tipo_negocio" :schema="schema" :required="true" />
          <Field v-model="tipo_negocio" name="tipo_negocio" as="select" class="inputtext"
            :class="{ 'error': errors.tipo_negocio }">
            <option value="">
              Selecionar
            </option>
            <option v-for="item in Object.values(tipoNegocio)" :key="item.valor" :value="item.valor">
              {{ item.nome }}
            </option>
          </Field>
          <ErrorMessage name="tipo_negocio" class="error-msg" />
        </div>
      </div>

      <div class="d-flex gap-3 w-100 mb-2">
        <div class="mb-2">
          <div>
            <LabelFromYup name="cep" :schema="schema" :required="true" />
            <Field v-model="cep" v-maska name="cep" type="text" placeholder="CEP" class="inputtext" maxlength="9"
              data-maska="#####-###" :class="{ 'error': errors.cep }" @focusout="getCep" />
            <ErrorMessage class="error-msg" name="cep" />
          </div>
          <span v-if="chamadasPendentes.emFoco">
            <font-awesome-icon icon="spinner" class="spinner mt-3" />
          </span>
        </div>

        <div class="mb-2 w-75">
          <LabelFromYup name="endereco" :schema="schema" :required="true" />
          <Field v-model="lista.logradouro" name="endereco" type="text" placeholder="Rua" class="inputtext"
            :class="{ 'error': errors.endereco }" />
          <ErrorMessage class="error-msg" name="endereco" />
        </div>

        <div class="mb-2 w-25">
          <LabelFromYup name="numero" :schema="schema" :required="true" />
          <Field name="numero" type="text" placeholder="Número" class="inputtext" :class="{ 'error': errors.numero }" />
          <ErrorMessage class="error-msg" name="numero" />
        </div>

        <div class="mb-3 w-25">
          <LabelFromYup name="latitude" :schema="schema" :required="true" />
          <Field v-model="latitude" name="latitude" type="text" placeholder="latitude" class="inputtext"
            :class="{ 'error': errors.latitude }" />
          <ErrorMessage class="error-msg" name="latitude" />
        </div>

        <div class="mb-3 w-25">
          <LabelFromYup name="longitude" :schema="schema" :required="true" />
          <Field v-model="longitude" name="longitude" type="text" placeholder="longitude" class="inputtext"
            :class="{ 'error': errors.longitude }" />
          <ErrorMessage class="error-msg" name="longitude" />
        </div>
      </div>

      <div class="d-flex gap-3 w-100 mb-2">
        <div class="mb-3 w-75">
          <LabelFromYup name="bairro" :schema="schema" :required="true" />
          <Field v-model="lista.bairro" name="bairro" type="text" placeholder="Bairro" class="inputtext"
            :class="{ 'error': errors.bairro }" />
          <ErrorMessage class="error-msg" name="bairro" />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup name="estado" :schema="schema" :required="true" />
          <Field v-model="lista.uf" name="estado" type="text" placeholder="Estado" class="inputtext"
            :class="{ 'error': errors.estado }" />
          <ErrorMessage class="error-msg" name="estado" />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup name="cidade" :schema="schema" :required="true" />
          <Field v-model="lista.localidade" name="cidade" type="text" placeholder="cidade" class="inputtext"
            :class="{ 'error': errors.cidade }" />
          <ErrorMessage class="error-msg" name="cidade" />
        </div>
      </div>

      <div class="d-flex gap-3 w-100 mb-2">
        <div class="mb-3 w-75">
          <LabelFromYup name="matricula" :schema="schema" />
          <Field name="matricula" type="text" placeholder="Matrícula do Imóvel" class="inputtext"
            :class="{ 'error': errors.matricula }" />
          <ErrorMessage class="error-msg" name="matricula" />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup name="cartorio" :schema="schema" />
          <Field name="cartorio" type="text" placeholder="Cartório" class="inputtext"
            :class="{ 'error': errors.cartorio }" />
          <ErrorMessage class="error-msg" name="cartorio" />
        </div>
      </div>

      <div class="mb-4 w-100">
        <LabelFromYup name="titulo" :schema="schema" />
        <Field name="titulo" type="text" placeholder="Título - Ex: Apartamento no Centro de Curitiba" class="inputtext"
          :class="{ 'error': errors.titulo }" />
        <ErrorMessage class="error-msg" name="titulo" />
      </div>

      <div class="d-flex gap-3 w-100 mb-2">
        <div class="mb-3 w-100">
          <LabelFromYup name="area_total" :schema="schema" :required="true" />
          <FloatMaskInput name="area_total" type="text" placeholder="Àrea Total" class="inputtext mb-2"
            :value="values.area_total" :class="{ 'error': errors.area_total }" converter-para="string" />
          <ErrorMessage class="error-msg" name="area_total" />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup name="area_util" :schema="schema" />
          <FloatMaskInput name="area_util" type="text" :value="values.area_util" placeholder="Área Útil"
            class="inputtext mb-2" converter-para="string" />
          <ErrorMessage class="error-msg" name="area_util" />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup :schema="schema" name="dormitorios" class="label" />
          <Field name="dormitorios" type="number" min="0" class="inputtext" />
          <ErrorMessage name="dormitorios" class="error-msg mb1" />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup :schema="schema" name="suites" class="label" />
          <Field name="suites" type="number" min="0" class="inputtext" />
          <ErrorMessage name="suites" class="error-msg" />
        </div>
      </div>

      <div class="d-flex gap-3 w-100 mb-2">
        <div class="mb-3 w-100">
          <LabelFromYup :schema="schema" name="vagas_garagem" class="label" />
          <Field name="vagas_garagem" type="number" min="0" class="inputtext" />
          <ErrorMessage name="vagas_garagem" class="error-msg" />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup :schema="schema" name="numero_banheiros" class="label" />
          <Field name="numero_banheiros" type="number" min="0" class="inputtext" />
          <ErrorMessage name="numero_banheiros" class="error-msg" />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup name="valor_condominio" :schema="schema" />
          <FloatMaskInput name="valor_condominio" type="text" placeholder="Valor do condomínio"
            :value="values.valor_condominio" class="inputtext" converter-para="string" />
          <ErrorMessage class="error-msg" name="valor_condominio" />
        </div>
      </div>

      <div class="d-flex gap-3 w-100 mb-2">
        <div class="mb-3 w-100">
          <LabelFromYup name="idade_imovel" :schema="schema" />
          <Field name="idade_imovel" type="text" placeholder="Idade do Imóvel" class="inputtext"
            :class="{ 'error': errors.idade_imovel }" />
          <ErrorMessage class="error-msg" name="idade_imovel" />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup name="andar_imovel" :schema="schema" />
          <Field name="andar_imovel" type="text" placeholder="Andar do Imóvel" class="inputtext"
            :class="{ 'error': errors.andar_imovel }" />
          <ErrorMessage class="error-msg" name="andar_imovel" />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup name="valor_iptu" :schema="schema" />
          <FloatMaskInput name="valor_iptu" type="text" placeholder="Valor do IPTU Por Ano" :value="values.valor_iptu"
            class="inputtext" converter-para="string" />
          <ErrorMessage class="error-msg" name="valor_iptu" />
        </div>
      </div>

      <div class="d-flex gap-3 mb-2">
        <div v-if="['Apartamento', 'Casa', 'Casa de Condomínio', 'Lote/Terreno'].includes(tipo_imovel)"
          class="d-flex w-100 align-items-center mb-2">
          <Field name="usar_area_construida" type="checkbox" :value="true" class="inputcheckbox" />
          <LabelFromYup name="usar_area_construida" :schema="schema" class="ps-2 pb-0" />
          <CTooltip v-if="tipo_imovel == 'Apartamento'" placement="top"
            content="Aqui você coloca a área de terraço ou de garden descoberta, coloque o tamanho da área e também o valor que você vai determinar, uma ideia é utilizar o CUB da sua região.">
            <template #toggler="{ id, on }">
              <font-awesome-icon :aria-describedby="id" icon="circle-info" v-on="on" />
            </template>
          </CTooltip>
          <button v-if="['Casa', 'Casa de Condomínio', 'Lote/Terreno'].includes(tipo_imovel)" type="button"
            class="ver-mais" @click="ativo = !ativo">
            <font-awesome-icon :icon="ativo ? 'circle-minus' : 'circle-plus'" />
            <CTooltip placement="top"
              content="Aqui você coloca a área de terraço ou de garden descoberta, coloque o tamanho da área e também o valor que você vai determinar, uma ideia é utilizar o CUB da sua região." />
          </button>
        </div>
      </div>

      <div v-if="['Casa', 'Casa de Condomínio', 'Lote/Terreno'].includes(tipo_imovel)" class="d-flex gap-3 mb-2">
        <div class="mb-2 w-100">
          <LabelFromYup name="area_construida" :schema="schema" />
          <FloatMaskInput name="area_construida" type="text" placeholder="Área construída (m²)" class="inputtext"
            :value="values.area_construida" converter-para="string" />
          <ErrorMessage class="error-msg" name="area_construida" />
        </div>

        <div class="mb-2 w-100">
          <LabelFromYup name="valor_metro_area_construida" :schema="schema" />
          <FloatMaskInput name="valor_metro_area_construida" type="text" placeholder="Valor m² área construída"
            class="inputtext" :value="values.valor_metro_area_construida" converter-para="string" />
          <ErrorMessage class="error-msg" name="valor_metro_area_construida" />
        </div>
      </div>

      <div v-if="ativo" class="d-flex gap-3 mb-2">
        <div class="mb-2 w-100">
          <LabelFromYup name="area_construida_2" :schema="schema" />
          <FloatMaskInput name="area_construida_2" type="text" placeholder="Área construída (m²) 2" class="inputtext"
            :value="values.area_construida_2" converter-para="string" />
          <ErrorMessage class="error-msg" name="area_construida_2" />
        </div>

        <div class="mb-2 w-100">
          <LabelFromYup name="valor_metro_area_construida_2" :schema="schema" />
          <FloatMaskInput name="valor_metro_area_construida_2" type="text" placeholder="Valor m² área construída 2"
            class="inputtext" :value="values.valor_metro_area_construida_2" converter-para="string" />
          <ErrorMessage class="error-msg" name="valor_metro_area_construida_2" />
        </div>
      </div>

      <div v-if="tipo_imovel === 'Apartamento'" class="d-flex gap-3 w-100 mb-2">
        <div class="mb-3 w-100">
          <LabelFromYup name="area_descoberta_privativa" :schema="schema" />
          <FloatMaskInput name="area_descoberta_privativa" type="text" placeholder="Área Descoberta Privativa(m²)"
            :value="values.area_descoberta_privativa" class="inputtext" converter-para="string" />
          <ErrorMessage class="error-msg" name="area_descoberta_privativa" />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup name="valor_metro_area_descoberta_privativa" :schema="schema" />
          <FloatMaskInput name="valor_metro_area_descoberta_privativa" type="text"
            placeholder="Valor do m2 da Área privativa" :value="values.valor_metro_area_descoberta_privativa"
            class="inputtext" converter-para="string" />
          <ErrorMessage class="error-msg" name="valor_metro_area_descoberta_privativa" />
        </div>
      </div>

      <div>
        <label>Características imóvel<span class="required">*</span></label>
        <QuillEditor id="caracteristicas" v-model:content="caracteristicas" content-type="html" theme="snow"
          class="mb-3" name="caracteristicas" />
      </div>

      <div class="d-flex flex-column align-items-center mt-4">
        <button :disabled="isSubmitting || requisicaoNavegacao === '0'"
          :class="{ 'BtnDisabled': requisicaoNavegacao === '0', 'btnRoxo': true }">
          Salvar
        </button>
        <font-awesome-icon v-show="isSubmitting" icon="spinner" class="spinner mt-3" />
      </div>
    </form>
  </div>
</template>

<script setup>
import FloatMaskInput from '@/components/FloatMaskInput.vue';
import { informacaoImovel as schema } from '@/consts/formSchema';
import tipoImovel from '@/consts/tipoImovel';
import tipoNegocio from '@/consts/tipoNegocio';
import urbanoRural from '@/consts/urbanoRural';
import { useAlertStore } from '@/store/alert.store';
import { useCepStore } from '@/store/buscaCep.store';
import { usePtamsStore } from '@/store/ptams.store';
import { useRequisicaoFinalidaeStore } from '@/store/requisicoesFinalidade.store';
import { Tab } from 'bootstrap';
import { vMaska } from "maska/vue";
import { storeToRefs } from 'pinia';
import { ErrorMessage, Field, useForm, } from 'vee-validate';
import { computed, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const alertStore = useAlertStore();

const cepGet = useCepStore()
const { lista, chamadasPendentes } = storeToRefs(cepGet);

const ptamsStore = usePtamsStore()
const { infoImovel, imovelId } = storeToRefs(ptamsStore);

const requisicoesFinalidadeStore = useRequisicaoFinalidaeStore()
const { requisicaoNavegacao } = storeToRefs(requisicoesFinalidadeStore);


const props = defineProps({
  ptamsId: {
    type: Number,
    default: 0,
  },
  laudoId: {
    type: Number,
    default: 0,
  }
});

const ativo = ref(false);
const tipo_imovel = ref(null);
const caracteristicas = ref('');
const latitude = ref('')
const longitude = ref('')

const enderecoCompleto = computed(() => lista.value.logradouro)
const tipo_negocio = ref('')

const {
  errors, isSubmitting, values, handleSubmit, resetForm
} = useForm({
  initialValues: infoImovel.value,
  validationSchema: schema,
});

function onDescriptionInput(content) {
  caracteristicas.value = content;
}

async function getCep(cep) {
  let ceptetste = cep.target.value.replace(/\D/g, '')
  try {
    await cepGet.getCep(ceptetste);
    latitude.value = cepGet.location.latitude;
    longitude.value = cepGet.location.longitude;
  } catch (error) {
    alertStore.error(error);
  }
}

const onSubmitInformacaoImoveis = handleSubmit(async (values) => {
  try {

    const payload = {
      ...values,
      latitude: latitude.value,
      longitude: longitude.value,
      caracteristicas: caracteristicas.value || infoImovel.value.caracteristicas,
      usar_area_construida: !!values.usar_area_construida
    };

    const resposta = props.ptamsId
      ? await ptamsStore.salvarItem(payload, props.ptamsId)
      : await ptamsStore.salvarItem(payload);

    if (resposta) {
      await ptamsStore.buscarInfoImovel(imovelId.value || props.ptamsId);
      await ptamsStore.buscarLaudo(imovelId.value || props.ptamsId);
      alertStore.success('Sucesso');
      const tabElement = document.getElementById('proprietario');
      const tabTrigger = new Tab(tabElement);
      tabTrigger.show();
    }

  } catch (error) {
    if (error.message.includes(
      "Cannot read properties of null (reading 'caracteristicas')"
    )) {
      alertStore.error('Características imóvel é obrigatório.');
    } else {
      alertStore.error('Erro ao salvar as informações.');
    }
  }
})

async function iniciar() {
  if (props.ptamsId) {
    await ptamsStore.buscarInfoImovel(props.ptamsId);
    ativo.value = (values.area_construida_2 > 0 && values.valor_metro_area_construida_2 > 0) ?? false;
  }
  await requisicoesFinalidadeStore.buscarTudo('ptam')
}
iniciar()

watch(infoImovel, (novoValor) => {
  resetForm({ values: novoValor });
  caracteristicas.value = novoValor.caracteristicas || '';
});
</script>

<style scoped>
.ativo svg {
  color: var(--roxo);
  background-color: initial;
  margin-left: initial;
}

.ver-mais {
  background-color: transparent;
  border: none;
  text-wrap: nowrap;
  color: var(--roxo);
  font-weight: 500;
}
</style>
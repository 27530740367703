<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div>
    <div class="d-flex justify-content-start gap-2 mb-2">
      <router-link :to="{ name: 'Lista ptams' }" class="btnVoltar">
        <font-awesome-icon icon="circle-left" />
      </router-link>

      <button v-if="props.ptamsId" class="btnBranco" type="button" @click="abrirModalPdf">
        <font-awesome-icon icon="file-pdf" />
        Gerar PDF
      </button>
      <button v-if="props.ptamsId" class="btnBranco" type="button" @click="abrirModalEmail(props.ptamsId)">
        <font-awesome-icon icon="envelope" />
        Enviar ptams por e-mail
      </button>
      <button v-if="props.ptamsId" class="btnBranco" @click="abrirModalFinalizado(props.ptamsId)">
        <font-awesome-icon icon="check-to-slot" />
        Enviada/Finalizada
      </button>

      <div v-if="mostrarModalEmail" class="modal fade show" style="display: block; background-color: rgba(0,0,0,0.5);"
        tabindex="-1" @click="fecharModalEmail">
        <div class="modal-dialog modal-dialog-centered" @click.stop>
          <div class="modal-content">
            <div class="modal-header justify-content-between">
              <h3> Enviar ptams por e-mail</h3>
              <button type="button" class="Modalclose" @click="fecharModalEmail" aria-label="Close">
                <font-awesome-icon icon="circle-xmark" />
              </button>
            </div>
            <div class="modal-body">
              <Form v-slot="{ errors, isSubmitting }" :validation-schema="schemaPtamsEmail" @submit="enviarOpiniao">
                <div class="mb-4">
                  <LabelFromYup name="email" :schema="schemaPtamsEmail" :required="true" />
                  <Field name="email" placeholder="seu_email@provedor.com" type="text" class="inputtext"
                    :class="{ 'error': errors.email }" />
                  <ErrorMessage class="error-msg" name="email" />
                </div>
                <div class="modal-footer py-0">
                  <button class="btnRoxo" :disabled="isSubmitting">
                    Enviar
                  </button>
                  <font-awesome-icon v-show="isSubmitting" icon="spinner" class="spinner mt-3" />
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>

      <div v-if="mostrarModalFinalizado" class="modal fade show"
        style="display: block; background-color: rgba(0,0,0,0.5);" tabindex="-1" @click="fecharModalFinalizado">
        <div class="modal-dialog modal-dialog-centered" @click.stop>
          <div class="modal-content">
            <div class="modal-header justify-content-between">
              <h3>Enviada/Finalizada</h3>
              <button type="button" class="Modalclose" @click="fecharModalFinalizado" aria-label="Close">
                <font-awesome-icon icon="circle-xmark" />
              </button>
            </div>
            <div class="modal-body">
              <Form v-slot="{ isSubmitting }" :validation-schema="SchemaFinalizado" :initial-values="laudo"
                @submit="enviarStatus">
                <div class="d-flex mb-2">
                  <Field name="finalizada" type="checkbox" :value="true" class="inputcheckbox" />
                  <LabelFromYup name="finalizada" :schema="SchemaFinalizado" class="ps-2 pb-0" />
                </div>
                <div v-if="laudo?.enviada" class="d-flex mb-2">
                  <Field name="enviada" type="checkbox" :value="true" class="inputcheckbox" disabled="disabled" />
                  <LabelFromYup name="enviada" :schema="SchemaFinalizado" class="ps-2 pb-0" />
                </div>
                <div class="modal-footer py-0">
                  <button class="btnRoxo" :disabled="isSubmitting">
                    Enviar
                  </button>
                  <font-awesome-icon v-show="isSubmitting" icon="spinner" class="spinner mt-3" />
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>

      <div v-if="mostrarModalPdf" class="modal fade show" style="display: block; background-color: rgba(0,0,0,0.5);"
        tabindex="-1" @click="fecharModalPdf">
        <div class="modal-dialog modal-dialog-centered" @click.stop>
          <div class="modal-content">
            <div class="modal-header justify-content-between">
              <h3>Escolha o template desejado</h3>
              <button type="button" class="Modalclose" @click="fecharModalPdf" aria-label="Close">
                <font-awesome-icon icon="circle-xmark" />
              </button>
            </div>
            <div class="modal-body">
              <Form v-slot="{ errors, isSubmitting }" :validation-schema="schemaLayout" @submit="enviarLayoutPdf">
                <div class="mb-3 w-100">
                  <Field v-model="layout" name="layout" as="select" class="inputtext"
                    :class="{ 'error': errors.layout }">
                    <option value="">
                      Selecionar
                    </option>
                    <option v-for="item in Object.values(layoutPdf)" :key="item.valor" :value="item.valor">
                      {{ item.nome }}
                    </option>
                  </Field>
                  <ErrorMessage name="layout" class="error-msg" />
                </div>
                <div class="layout">
                  <div v-if="layout == 'default'">
                    <img src="../../assets/imagens/capa-ptam-new.png">
                  </div>
                  <div v-else-if="layout === 'clean'">
                    <img src="../../assets/imagens/ptam.jpg">
                  </div>
                  <div v-else />
                </div>
                <div class="modal-footer py-0">
                  <button class="btnRoxo" :disabled="isSubmitting">
                    Gerar PDF
                  </button>
                  <font-awesome-icon v-show="isSubmitting" icon="spinner" class="spinner mt-3" />
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="card-branco">

      <div class="d-flex justify-content-between align-items-center pb-4">
        <h1>{{ route?.meta?.título }}</h1>
      </div>
      <ul id="pills-tab" class="nav nav-pills mb-4 gap-2" role="tablist">
        <button id="informacaoImovel" class="nav-link active" data-bs-toggle="pill" data-bs-target="#informacaoImoveis"
          aria-selected="true">
          Informações do imóvel
        </button>
        <button id="proprietario" class="nav-link" data-bs-toggle="pill" data-bs-target="#proprietarios"
          aria-selected="false" :disabled="!(props.ptamsId || imovelId)">
          Proprietário
        </button>
        <button class="nav-link" id="fotosImovel" data-bs-toggle="pill" data-bs-target="#fotoImovel"
          aria-selected="false" :disabled="!(infoImovel?.proprietario_id !== null) || !infoImovel">
          Fotos do imóvel
        </button>
        <button id="amostra" class="nav-link" data-bs-toggle="pill" data-bs-target="#amostras" aria-selected="false"
          :disabled="!(infoImovel?.proprietario_id !== null) || !infoImovel">
          Amostras
        </button>
        <button id="calculo" class="nav-link" data-bs-toggle="pill" data-bs-target="#calculos" aria-selected="false"
          :disabled="!(infoImovel?.proprietario_id !== null) || !listaCalculo.length">
          Cálculos
        </button>
        <button id="avaliacao" class="nav-link" data-bs-toggle="pill" data-bs-target="#avaliacoes" aria-selected="false"
          :disabled="!(infoImovel?.proprietario_id !== null) || !props.ptamsId">
          Avaliação
        </button>
      </ul>
      <div id="pills-tabContent" class="tab-content">
        <div id="informacaoImoveis" class="tab-pane fade show active" role="tabpanel" aria-labelledby="informacaoImovel"
          tabindex="0">
          <InformacaoImoveis :ptams-id="ptamsId" />
        </div>
        <div id="proprietarios" class="tab-pane fade" role="tabpanel" aria-labelledby="proprietario" tabindex="0">
          <InformacaoProprietario :ptams-id="ptamsId" />
        </div>
        <div id="fotoImovel" class="tab-pane fade" role="tabpanel" aria-labelledby="fotosImovel" tabindex="0">
          <FotosImovel :ptams-id="ptamsId" />
        </div>
        <div id="amostras" class="tab-pane fade" role="tabpanel" aria-labelledby="amostra" tabindex="0">
          <Amostras :ptams-id="ptamsId" />
        </div>
        <div id="avaliacoes" class="tab-pane fade" role="tabpanel" aria-labelledby="avaliacao" tabindex="0">
          <Avaliacao :ptams-id="ptamsId" :laudo-id="laudoId" />
        </div>
        <div id="calculos" class="tab-pane fade" role="tabpanel" aria-labelledby="calculo" tabindex="0">
          <Calculo :ptams-id="ptamsId" :laudo-id="laudoId" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  status as SchemaFinalizado,
  layout as schemaLayout,
  email as schemaPtamsEmail,
} from '@/consts/formSchema';
import layoutPdf from '@/consts/layoutPdf';
import { useAlertStore } from '@/store/alert.store';
import { usePtamsStore } from '@/store/ptams.store';
import { storeToRefs } from 'pinia';
import { ErrorMessage, Field, Form } from 'vee-validate';
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import Amostras from './Amostras.vue';
import Avaliacao from './Avaliacao.vue';
import Calculo from './Calculo.vue';
import FotosImovel from './FotosImovel.vue';
import InformacaoImoveis from './InformacaoImoveis.vue';
import InformacaoProprietario from './informacaoProprietario.vue';

const ptamsStore = usePtamsStore()
const { infoImovel, imovelId, laudoIdGet, listaCalculo, proprietarioId, laudo } = storeToRefs(ptamsStore);

const alertStore = useAlertStore();
const route = useRoute();

const mostrarModalFinalizado = ref(false)
const mostrarModalEmail = ref(false)
const idSelecionado = ref(null);
const mostrarModalPdf = ref(false)
const layout = ref('default')

const props = defineProps({
  ptamsId: {
    type: Number,
    default: 0,
  },
  laudoId: {
    type: Number,
    default: 0,
  },
});

function abrirModalPdf() {
  mostrarModalPdf.value = true;
  document.body.style.overflow = 'hidden';
}

function fecharModalPdf() {
  mostrarModalPdf.value = false;
  document.body.style.overflow = 'auto';
}

function abrirModalFinalizado(id) {
  idSelecionado.value = id;
  mostrarModalFinalizado.value = true;
  document.body.style.overflow = 'hidden';
}

function fecharModalFinalizado() {
  mostrarModalFinalizado.value = false;
  document.body.style.overflow = 'auto';
}

function abrirModalEmail(id) {
  idSelecionado.value = id;
  mostrarModalEmail.value = true;
  document.body.style.overflow = 'hidden';
}

function fecharModalEmail() {
  mostrarModalEmail.value = false;
  document.body.style.overflow = 'auto';
}

async function enviarStatus(status) {
  try {
    if (props.laudoId || laudoIdGet.value) {
      await ptamsStore.enviarStatus(
        props.laudoId || laudoIdGet.value,
        status
      )
      fecharModalFinalizado()
    }
  } catch (erro) {
    alertStore.error(erro);
  }
}

async function enviarOpiniao(email) {
  try {
    if (props.laudoId || laudoIdGet.value) {
      const resposta = await ptamsStore.enviarPtamsEmail(
        props.laudoId || laudoIdGet.value,
        email
      )
      if (resposta) {
        fecharModalEmail()
        iniciar()
      }
    }
  } catch (erro) {
    alertStore.error(erro);
  }
}

async function enviarLayoutPdf(value) {
  try {
    if (props.laudoId || laudoIdGet.value) {
      const resposta = await ptamsStore.gerarPdf(props.laudoId || laudoIdGet.value, value)
      const pdfLink = resposta;
      window.open(pdfLink, '_blank');
      fecharModalPdf();
    }
  } catch (erro) {
    alertStore.error(erro);
  }
}

async function iniciar() {
  if (props.ptamsId) {
    await ptamsStore.buscarLaudo(props.ptamsId)
    await ptamsStore.buscarInfoImovel(props.ptamsId)
  }

  if (props.laudoId || laudoIdGet.value) {
    await ptamsStore.buscarLaudoId(props.laudoId || laudoIdGet.value)
  }
}
iniciar()
</script>

<style scoped>
.ativo svg {
  color: var(--roxo);
  background-color: initial;
  margin-left: initial;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background-color: var(--cinzaClaro);
  color: var(--roxo);
  border: 1px solid var(--roxo);
  font-weight: bold;
}

.nav-link {
  color: var(--cinzaEscuro);
  background-color: var(--cinza);
}

.nav-link:focus,
.nav-link:hover {
  color: var(--branco);
  background: var(--roxo);
}
</style>
<template>
  <template v-if="!emFoco">
    <div class="d-flex">
      <font-awesome-icon icon="spinner" class="spinner" />
      <h3>Carregando...</h3>
    </div>
  </template>
  <template v-else>
    <div>
      <div class="d-flex justify-content-start gap-2 mb-2">
        <router-link to="/pesquisaInteligente" class="btnVoltar">
          <font-awesome-icon icon="circle-left" />
        </router-link>
        <button class="btnBranco" @click="abrirModalPdf" type="button">
          <font-awesome-icon icon="file-pdf" />
          Gerar PDF
        </button>
        <button class="btnBranco" @click="abrirModalEmailOpiniao" type="button">
          <font-awesome-icon icon="envelope" />
          Enviar Pesquisa Inteligente por e-mail
        </button>
        <button class="btnBranco" @click="abrirModalFinalizado(props.pesquisaInteligenteId)">
          <font-awesome-icon icon="check-to-slot" />
          Enviada/Finalizada
        </button>
        <div v-if="mostrarModal" class="modal fade show" style="display: block; background-color: rgba(0,0,0,0.5);"
          tabindex="-1" @click="fecharModalEmailOpiniao">
          <div class="modal-dialog modal-dialog-centered" @click.stop>
            <div class="modal-content">
              <div class="modal-header justify-content-between">
                <h3> Enviar Pesquisa Inteligente por e-mail</h3>
                <button type="button" class="Modalclose" @click="fecharModalEmailOpiniao" aria-label="Close">
                  <font-awesome-icon icon="circle-xmark" />
                </button>
              </div>
              <div class="modal-body">
                <Form v-slot="{ errors, isSubmitting }" :validation-schema="schemaOpiniaoEmail" @submit="enviarOpiniao">
                  <div class="mb-4">
                    <LabelFromYup name="email" :schema="schemaOpiniaoEmail" :required="true" />
                    <Field name="email" placeholder="seu_email@provedor.com" type="text" class="inputtext"
                      :class="{ 'error': errors.email }" />
                    <ErrorMessage class="error-msg" name="email" />
                  </div>
                  <div class="modal-footer py-0">
                    <button class="btnRoxo" :disabled="isSubmitting">
                      Enviar
                    </button>
                    <font-awesome-icon v-show="isSubmitting" icon="spinner" class="spinner mt-3" />
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>

        <div v-if="mostrarModalPdf" class="modal fade show" style="display: block; background-color: rgba(0,0,0,0.5);"
          tabindex="-1" @click="fecharModalPdf">
          <div class="modal-dialog modal-dialog-centered" @click.stop>
            <div class="modal-content">
              <div class="modal-header justify-content-between">
                <h3>Escolha o template desejado</h3>
                <button type="button" class="Modalclose" @click="fecharModalPdf" aria-label="Close">
                  <font-awesome-icon icon="circle-xmark" />
                </button>
              </div>
              <div class="modal-body">
                <Form v-slot="{ errors, isSubmitting }" :validation-schema="schemaLayout" @submit="enviarLayoutPdf">
                  <div class="mb-3 w-100">
                    <Field v-model="layout" name="layout" as="select" class="inputtext"
                      :class="{ 'error': errors.layout }">
                      <option value="">
                        Selecionar
                      </option>
                      <option v-for="item in Object.values(layoutPdf)" :key="item.valor" :value="item.valor">
                        {{ item.nome }}
                      </option>
                    </Field>
                    <ErrorMessage name="layout" class="error-msg" />
                  </div>
                  <div class="layout">
                    <div v-if="layout == 'default'">
                      <img src="../../assets/imagens/padrao.jpg">
                    </div>
                    <div v-else-if="layout === 'clean'">
                      <img src="../../assets/imagens/clean.jpg">
                    </div>
                    <div v-else />
                  </div>
                  <div class="modal-footer py-0">
                    <button class="btnRoxo" :disabled="isSubmitting">
                      Gerar PDF
                    </button>
                    <font-awesome-icon v-show="isSubmitting" icon="spinner" class="spinner mt-3" />
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>

        <div v-if="mostrarModalFinalizado" class="modal fade show"
          style="display: block; background-color: rgba(0,0,0,0.5);" tabindex="-1" @click="fecharModalFinalizado">
          <div class="modal-dialog modal-dialog-centered" @click.stop>
            <div class="modal-content">
              <div class="modal-header justify-content-between">
                <h3>Enviada/Finalizada</h3>
                <button type="button" class="Modalclose" aria-label="Close" @click="fecharModalFinalizado">
                  <font-awesome-icon icon="circle-xmark" />
                </button>
              </div>
              <div class="modal-body">
                <Form v-slot="{ isSubmitting }" :validation-schema="SchemaFinalizado" :initial-values="emFoco"
                  @submit="enviarStatus">
                  <div class="d-flex mb-2">
                    <Field name="finalizada" type="checkbox" :value="true" class="inputcheckbox" />
                    <LabelFromYup name="finalizada" :schema="SchemaFinalizado" class="ps-2 pb-0 font-weight-normal" />
                  </div>
                  <div v-if="emFoco?.enviada" class="d-flex mb-2">
                    <Field name="enviada" type="checkbox" :value="true" class="inputcheckbox" disabled="disabled" />
                    <LabelFromYup name="enviada" :schema="SchemaFinalizado" class="ps-2 pb-0 font-weight-normal" />
                  </div>
                  <div class="modal-footer py-0">
                    <button class="btnRoxo" :disabled="isSubmitting">
                      Enviar
                    </button>
                    <font-awesome-icon v-show="isSubmitting" icon="spinner" class="spinner mt-3" />
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-branco">
        <h1 class="mb-3">Visualizar Pesquisa Inteligente</h1>
        <p>Amostras do trabalho {{ emFoco.id }} - Pesquisa inteligente no Endereço {{ emFoco.endereco }}</p>

        <div v-for="(amostra, index) in emFoco?.amostras" :key="index">
          <div class="bordaCiano mt-3 d-flex gap-3 py-3 px-3 text-start">
            <div class="w-25">
              <p class="fw-bolder">Endereço</p>
              <p>{{ amostra.logradouro ? amostra.logradouro : '-' }}</p>
            </div>
            <div class="w-25">
              <p class="fw-bolder">Anunciante</p>
              <p>{{ amostra.anunciante }}</p>
            </div>
            <div class="w-50">
              <p class="fw-bolder">Link do anuncio</p>
              <a :href="amostra.anuncioUrl" target="_blank" class="d-block" rel="noopener noreferrer">
                {{ amostra.anuncioUrl }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script setup>
import finalizado from '@/consts/finalizado';
import { status as SchemaFinalizado, email as schemaOpiniaoEmail, } from '@/consts/formSchema';
import layoutPdf from '@/consts/layoutPdf';
import { useAlertStore } from '@/store/alert.store';
import { usePesquisaInteligenteStore } from '@/store/pesquisaInteligente.store';
import { storeToRefs } from 'pinia';
import { ErrorMessage, Field, Form } from 'vee-validate';
import { ref } from 'vue';

const alertStore = useAlertStore();

const pesquisaInteligenteStore = usePesquisaInteligenteStore()
const { emFoco, filePath } = storeToRefs(pesquisaInteligenteStore);

const mostrarModal = ref(false)
const mostrarModalPdf = ref(false)
const mostrarModalFinalizado = ref(false)
const idSelecionado = ref(null);

const props = defineProps({
  pesquisaInteligenteId: {
    type: Number,
    default: 0,
  },
});

function abrirModalEmailOpiniao() {
  mostrarModal.value = true;
  document.body.style.overflow = 'hidden';
}

function fecharModalEmailOpiniao() {
  mostrarModal.value = false;
  document.body.style.overflow = 'auto';
}

function abrirModalPdf() {
  mostrarModalPdf.value = true;
  document.body.style.overflow = 'hidden';
}

function fecharModalPdf() {
  mostrarModalPdf.value = false;
  document.body.style.overflow = 'auto';
}

function abrirModalFinalizado(id) {
  idSelecionado.value = id;
  mostrarModalFinalizado.value = true;
  document.body.style.overflow = 'hidden';
}

function fecharModalFinalizado() {
  mostrarModalFinalizado.value = false;
  document.body.style.overflow = 'auto';
}

async function enviarStatus(status) {
  try {
    if (props.pesquisaInteligenteId) {
      await pesquisaInteligenteStore.enviarStatus(
        props.pesquisaInteligenteId,
        status
      )
      fecharModalFinalizado()
    }
  } catch (erro) {
    alertStore.error(erro);
  }
}

async function enviarOpiniao(email) {
  try {
    if (props.pesquisaInteligenteId) {
      await pesquisaInteligenteStore.enviarOpiniaoEmail(props.pesquisaInteligenteId, email)
      fecharModalEmailOpiniao();
      iniciar()
    }
  } catch (erro) {
    alertStore.error(erro);
  }
}

async function enviarLayoutPdf(value) {
  try {
    if (props.pesquisaInteligenteId) {
      const resposta = await pesquisaInteligenteStore.gerarPdf(props.pesquisaInteligenteId, value)
      const pdfLink = resposta;
      window.open(pdfLink, '_blank');
      fecharModalPdf();
    }
  } catch (erro) {
    alertStore.error(erro);
  }
}

async function iniciar() {
  if (props.pesquisaInteligenteId) {
    await pesquisaInteligenteStore.buscarItem(props.pesquisaInteligenteId);
  }
}
iniciar()
</script>

<style scoped>
label {
  font-weight: bold
}

.ativo svg {
  background: initial;
  color: var(--roxo);
}

.imagem-imovel img {
  max-width: 250px;
  border-radius: 18px;
  width: 250px;
}
</style>
<template>
  <div>
    <div class="pb-4">
      <div class="d-flex justify-content-between align-items-center pb-2">
        <h1 class="pb-2">Assinatura</h1>
        <button class="btnBranco" @click="abrirModalFinalizado()">
          <font-awesome-icon icon="fa-solid fa-circle-plus" />
          Adicionar assinatura
        </button>
      </div>
      <table class="w-100 mb-3">
        <col>
        <col>
        <col>
        <thead>
          <tr>
            <th>Plano</th>
            <th>Adesão</th>
            <th>Expiração</th>
            <th>Método de Pagamento</th>
            <th>Criado por</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in assinaturas" :key="item.id">
            <td>{{ item.plan_name }}</td>
            <td>{{ item.data_adesao ? dateToField(item.data_adesao) : '-' }}</td>
            <td>{{ item.data_expiracao ? dateToField(item.data_expiracao) : '-' }}</td>
            <td>{{ item.metodo_pagamento ? item.metodo_pagamento : '-' }}</td>
            <td>{{ item.criado_por ? item.criado_por : '-' }}</td>
          </tr>
          <tr v-if="chamadasPendentes.assinaturas">
            <td colspan="3">
              <font-awesome-icon icon="spinner" class="spinner mt-3" />
              Carregando
            </td>
          </tr>
          <tr v-else-if="erro">
            <td colspan="3">
              Erro: {{ erro }}
            </td>
          </tr>
          <tr v-else-if="!assinaturas.length">
            <td colspan="3">
              Nenhum resultado encontrado.
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="mostrarModalAssinatura" class="modal fade show"
      style="display: block; background-color: rgba(0,0,0,0.5);" tabindex="-1" @click="fecharModalFinalizado">
      <div class="modal-dialog modal-dialog-centered" @click.stop>
        <div class="modal-content">
          <div class="modal-header justify-content-between">
            <h3>Criar assinatura</h3>
            <button type="button" class="Modalclose" @click="fecharModalFinalizado" aria-label="Close">
              <font-awesome-icon icon="circle-xmark" />
            </button>
          </div>
          <div class="modal-body">
            <form @submit="onSubmitAssinatura">
              <div v-if="emFoco?.user.name" class="mb-4 w-100">
                <LabelFromYup name="user_id" :schema="schema" />
                <p class="inputtext">{{ emFoco?.user.name }}</p>
                <Field :value="emFoco.user.id" name="user_id" type="text" class="inputtext hidden-field" />
              </div>

              <div class="mb-3 w-100">
                <LabelFromYup name="plano_id" :schema="schema" />
                <Field name="plano_id" as="select" class="inputtext">
                  <option value="">
                    Selecionar
                  </option>
                  <option v-for="item in Object.values(planosRegulares)" :key="item.valor" :value="item.id">
                    {{ item.nome }}
                  </option>
                </Field>
              </div>

              <div class="mb-3 w-100">
                <LabelFromYup name="data_expiracao" :schema="schema" />
                <Field name="data_expiracao" type="datetime-local" class="inputtext" maxlength="10" />
              </div>

              <div class="mb-3 w-100">
                <LabelFromYup name="metodo_pagamento" :schema="schema" />
                <Field v-model="metodo_pagamento" name="metodo_pagamento" as="select" class="inputtext">
                  <option v-for="item in Object.values(metodoPagamento)" :key="item.valor" :value="item.valor">
                    {{ item.nome }}
                  </option>
                </Field>
              </div>
              <div class="modal-footer py-0">
                <button class="btnBranco" data-bs-dismiss="modal">
                  Fechar
                </button>
                <button class="btnRoxo" :disabled="isSubmitting">
                  Enviar
                </button>
                <font-awesome-icon v-show="isSubmitting" icon="spinner" class="spinner mt-3" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { criarAssinaturaUsuario as schema } from '@/consts/formSchema';
import metodoPagamento from '@/consts/metodoPagamento';
import dateToField from '@/helpers/dateToFiel';
import { useAlertStore } from '@/store/alert.store';
import { useClientesStore } from '@/store/clientes.store';
import { usePlanoStore } from '@/store/plano.store';
import { storeToRefs } from 'pinia';
import { Field, useForm } from 'vee-validate';
import { ref } from 'vue';

const meusClientesStore = useClientesStore()
const { emFoco, assinaturas, chamadasPendentes, erro } = storeToRefs(meusClientesStore);

const planoStore = usePlanoStore()
const { planosRegulares } = storeToRefs(planoStore);

const alertStore = useAlertStore();

const mostrarModalAssinatura = ref(false)
const idSelecionado = ref(null);
const metodo_pagamento = ref('Boleto Bancário')
const props = defineProps({
  clienteId: {
    type: Number,
    default: 0,
  },
});

const {
  values, handleSubmit
} = useForm({
  validationSchema: schema,
});

function abrirModalFinalizado() {
  idSelecionado.value = props.clienteId;
  mostrarModalAssinatura.value = true;
  document.body.style.overflow = 'hidden';
}

function fecharModalFinalizado() {
  mostrarModalAssinatura.value = false;
  document.body.style.overflow = 'auto';
}

const onSubmitAssinatura = handleSubmit(async (values) => {
  try {
    const payload = {
      ...values,
    };

    const resposta = await meusClientesStore.criarAssinatura(payload);
    if (resposta) {
      alertStore.success('Assinatura Criada com Sucesso');
      fecharModalFinalizado()
    }
    meusClientesStore.buscarAssinaturas(props.clienteId)
  } catch (erros) {
    alertStore.error(erros.message);
  }
})
</script>

<style scoped>
.btnBranco {
  color: var(--roxo)
}
</style>
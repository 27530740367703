<template>
  <div class="card-branco">

    <div class="d-flex gap-3 mb-3 align-items-center">
      <router-link to="/imoveisVendidos" class="btnVoltar">
        <font-awesome-icon icon="circle-left" />
      </router-link>
      <h1>{{ route?.meta?.título }}</h1>
    </div>
    <form @submit.prevent="onSubmitImoveis">
      <div class="d-flex gap-3 w-100 mb-2">
        <div class="mb-3 w-100">
          <LabelFromYup name="tipo_imovel" :schema="schema" :required="true" />
          <Field name="tipo_imovel" as="select" class="inputtext" :class="{ 'error': errors.tipo_imovel }">
            <option value="">
              Selecionar
            </option>
            <option v-for="item in Object.values(tipoImovel)" :key="item.valor" :value="item.valor">
              {{ item.valor }}
            </option>
          </Field>
          <ErrorMessage name="tipo_imovel" class="error-msg" />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup name="nome_edificio" :schema="schema" />
          <Field name="nome_edificio" type="text" placeholder="Nome do edifício" class="inputtext"
            :class="{ 'error': errors.nome_edificio }" />
          <ErrorMessage class="error-msg" name="nome_edificio" />
        </div>
      </div>

      <div class="d-flex gap-3 w-100 mb-2">
        <div class="mb-3 w-25">
          <LabelFromYup name="idade" :schema="schema" />
          <Field name="idade" type="text" placeholder="idade" class="inputtext" :class="{ 'error': errors.idade }" />
          <ErrorMessage class="error-msg" name="idade" />
        </div>

        <div class="mb-2 w-25 d-flex align-items-center">
          <div>
            <LabelFromYup name="cep" :schema="schema" :required="true" />
            <Field v-model="cep" v-maska name="cep" type="text" placeholder="CEP" class="inputtext" maxlength="9"
              data-maska="#####-###" :class="{ 'error': errors.cep }" @focusout="getCep" />
            <ErrorMessage class="error-msg" name="cep" />
          </div>
          <span v-if="chamadasPendentes.emFoco">
            <font-awesome-icon icon="spinner" class="spinner mt-3" />
          </span>
        </div>

        <div class="mb-2 w-75">
          <LabelFromYup name="endereco" :schema="schema" :required="true" />
          <Field v-model="lista.logradouro" name="endereco" type="text" placeholder="Rua" class="inputtext"
            :class="{ 'error': errors.endereco }" />
          <ErrorMessage class="error-msg" name="endereco" />
        </div>

        <div class="mb-3 w-25">
          <LabelFromYup name="numero" :schema="schema" />
          <Field name="numero" type="text" placeholder="Número" class="inputtext" :class="{ 'error': errors.numero }" />
          <ErrorMessage class="error-msg" name="numero" />
        </div>
      </div>

      <div class="d-flex gap-3 w-100 mb-2">
        <div class="mb-3 w-75">
          <LabelFromYup name="bairro" :schema="schema" :required="true" />
          <Field v-model="lista.bairro" name="bairro" type="text" placeholder="Bairro" class="inputtext"
            :class="{ 'error': errors.bairro }" />
          <ErrorMessage class="error-msg" name="bairro" />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup name="estado" :schema="schema" :required="true" />
          <Field v-model="lista.uf" name="estado" type="text" placeholder="Estado" class="inputtext"
            :class="{ 'error': errors.estado }" />
          <ErrorMessage class="error-msg" name="estado" />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup name="cidade" :schema="schema" :required="true" />
          <Field v-model="lista.localidade" name="cidade" type="text" placeholder="cidade" class="inputtext"
            :class="{ 'error': errors.cidade }" />
          <ErrorMessage class="error-msg" name="cidade" />
        </div>
      </div>

      <hr class="my-5">

      <div class="d-flex gap-3 w-100 mb-2">
        <div class="mb-3 w-25">
          <LabelFromYup name="valor_area_total" :schema="schema" :required="true" />
          <FloatMaskInput name="valor_area_total" type="text" placeholder="Valor da area total"
            :class="{ 'error': errors.valor_area_total }" class="inputtext mb-2" :value="values.valor_area_total"
            converter-para="string" @update:model-value="(newValue) => updateValoresM2('valor_area_total', newValue)" />
          <ErrorMessage class="error-msg" name="valor_area_total" />
        </div>

        <div class="mb-3 w-25">
          <LabelFromYup name="valor_area_privativa" :schema="schema" />
          <FloatMaskInput name="valor_area_privativa" type="text" :value="values.valor_area_privativa"
            placeholder="Valor da area privativa" class="inputtext mb-2" converter-para="string"
            @update:model-value="(newValue) => updateValoresM2('valor_area_privativa', newValue)" />
          <ErrorMessage class="error-msg" name="valor_area_privativa" />
        </div>

        <div class="mb-2 w-25">
          <LabelFromYup name="valor_avaliacao" :schema="schema" />
          <FloatMaskInput name="valor_avaliacao" type="text" :value="values.valor_avaliacao"
            placeholder="Valor da privativa R$M²" class="inputtext" converter-para="string" />
          <ErrorMessage class="error-msg" name="valor_avaliacao" />
        </div>

        <div class="mb-3 w-25">
          <LabelFromYup name="valor_divulgacao" :schema="schema" />
          <FloatMaskInput name="valor_divulgacao" type="text" placeholder="Valor de divulgação"
            :value="values.valor_divulgacao" class="inputtext" converter-para="string" />
          <ErrorMessage class="error-msg" name="valor_divulgacao" />
        </div>
      </div>

      <div class="d-flex gap-3 w-100 mb-2">
        <div class="mb-3 w-25">
          <LabelFromYup name="valor_fechamento" :schema="schema" :required="true" />
          <FloatMaskInput name="valor_fechamento" type="text" placeholder="Valor do fechamento" class="inputtext mb-2"
            :value="values.valor_fechamento" :class="{ 'error': errors.valor_fechamento }" converter-para="string"
            @update:model-value="(newValue) => updateValoresM2('valor_fechamento', newValue)" />
          <ErrorMessage class="error-msg" name="valor_fechamento" />
        </div>

        <div class="mb-3 w-25">
          <LabelFromYup name="valor_area_total_m2" :schema="schema" />
          <FloatMaskInput name="valor_area_total_m2" type="text" placeholder="Valor da área total R$M²"
            :value="values.valor_area_total_m2" class="inputtext mb-2" :class="{ 'error': errors.valor_area_total_m2 }"
            converter-para="string" />
          <ErrorMessage class="error-msg" name="valor_area_total_m2" />
        </div>

        <div class="mb-2 w-25">
          <LabelFromYup name="valor_area_privativa_m2" :schema="schema" />
          <FloatMaskInput name="valor_area_privativa_m2" type="text" placeholder="Valor da privativa R$M²"
            class="inputtext" :value="values.valor_area_privativa_m2" converter-para="string" />
          <ErrorMessage class="error-msg" name="valor_area_privativa_m2" />
        </div>

        <div class="mb-3 w-25">
          <LabelFromYup name="valor_condominio" :schema="schema" />
          <FloatMaskInput name="valor_condominio" type="text" placeholder="Valor do condomínio"
            :value="values.valor_condominio" class="inputtext" converter-para="string" />
          <ErrorMessage class="error-msg" name="valor_condominio" />
        </div>
      </div>

      <div class="d-flex gap-3 w-100 mb-2">
        <div class="mb-3 w-100">
          <LabelFromYup :schema="schema" name="dormitorios" class="label" />
          <Field name="dormitorios" type="number" min="0" class="inputtext" />
          <ErrorMessage name="dormitorios" class="error-msg mb1" />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup :schema="schema" name="suites" class="label" />
          <Field name="suites" type="number" min="0" class="inputtext" />
          <ErrorMessage name="suites" class="error-msg" />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup :schema="schema" name="vagas_garagem" class="label" />
          <Field name="vagas_garagem" type="number" min="0" class="inputtext" />
          <ErrorMessage name="vagas_garagem" class="error-msg" />
        </div>
      </div>

      <div class="d-flex gap-3 w-100 mb-2">
        <div class="mb-3 w-100">
          <LabelFromYup name="condicao" :schema="schema" :required="true" />
          <Field name="condicao" as="select" class="inputtext mb1" :class="{ 'error': errors.condicao }">
            <option value="">
              Selecionar
            </option>
            <option v-for="item in Object.values(condicaoImovel)" :key="item.valor" :value="item.valor">
              {{ item.valor }}
            </option>
          </Field>
          <ErrorMessage name="condicao" class="error-msg" />
        </div>

        <div class="mb-3">
          <LabelFromYup name="data_de_divulgacao" :schema="schema" />
          <Field name="data_de_divulgacao" type="date" class="inputtext light mb1"
            :class="{ 'error': errors.data_de_divulgacao }" maxlength="10" />
          <ErrorMessage name="data_de_divulgacao" class="error-msg" />
        </div>

        <div class="mb-3">
          <LabelFromYup name="data_de_venda" :schema="schema" />
          <Field name="data_de_venda" type="date" class="inputtext light mb1" :class="{ 'error': errors.data_de_venda }"
            maxlength="10" />
          <ErrorMessage name="data_de_venda" class="error-msg" />
        </div>
      </div>

      <div class="d-flex flex-column align-items-center mt-4">
        <button class="btnRoxo" :disabled="isSubmitting">
          Salvar
        </button>
        <font-awesome-icon v-show="isSubmitting" icon="spinner" class="spinner mt-3" />
      </div>
    </form>
  </div>
</template>

<script setup>
import FloatMaskInput from '@/components/FloatMaskInput.vue';
import condicaoImovel from '@/consts/condicaoImovel';
import { novoImovel as schema } from '@/consts/formSchema';
import tipoImovel from '@/consts/tipoImovel';
import { useAlertStore } from '@/store/alert.store';
import { useCepStore } from '@/store/buscaCep.store';
import { useImoveisVendidosStore } from '@/store/imoveisVendidos.store';
import { vMaska } from "maska/vue";
import { storeToRefs } from 'pinia';
import { ErrorMessage, Field, useForm, } from 'vee-validate';
import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const alertStore = useAlertStore();

const imoveisVendidos = useImoveisVendidosStore()
const cepGet = useCepStore()

const { lista, chamadasPendentes } = storeToRefs(cepGet);
const { emFoco } = storeToRefs(imoveisVendidos);

const router = useRouter();
const route = useRoute();
const props = defineProps({
  imovelVendidoId: {
    type: Number,
    default: 0,
  },
});

const camposModificados = ref(false);
const location = ref({
  latitude: '',
  longitude: ''
})

const {
  errors, isSubmitting, setFieldValue, resetForm, values, handleSubmit,
} = useForm({
  initialValues: emFoco.value,
  validationSchema: schema,
});

async function getCep(cep) {
  let ceptetste = cep.target.value.replace(/\D/g, '')
  try {
    await cepGet.getCep(ceptetste);
    location.value.latitude = cepGet.location.latitude;
    location.value.longitude = cepGet.location.longitude;
  } catch (error) {
    alertStore.error(error);
  }
}

const updateValoresM2 = (fieldName, newValue) => {
  camposModificados.value = true;

  const valorFechamento = parseFloat(values.valor_fechamento) || 0;
  const areaTotal = fieldName === 'valor_area_total'
    ? parseFloat(newValue) || 0
    : parseFloat(values.valor_area_total) || 0;
  const areaPrivativa = fieldName === 'valor_area_privativa'
    ? parseFloat(newValue) || 0
    : parseFloat(values.valor_area_privativa) || 0;

  if (valorFechamento > 0) {
    const totalM2 = areaTotal > 0 ? valorFechamento / areaTotal : 0;
    const privativaM2 = areaPrivativa > 0 ? valorFechamento / areaPrivativa : 0;
    setFieldValue('valor_area_total_m2', totalM2.toFixed(2));
    setFieldValue('valor_area_privativa_m2', privativaM2.toFixed(2));
  }
};

const onSubmitImoveis = handleSubmit(async (values) => {
  try {
    const msg = props.imovelVendidoId
      ? 'Dados salvos com sucesso!'
      : 'Item adicionado com sucesso!';

    const payload = {
      ...values,
      latitude: location.value.latitude,
      longitude: location.value.longitude,
      id: null
    };

    const resposta = props.imovelVendidoId
      ? await imoveisVendidos.salvarItem(values, props.imovelVendidoId)
      : await imoveisVendidos.salvarItem(payload);

    if (resposta) {
      alertStore.success(msg);
      imoveisVendidos.$reset();
      router.push({ name: "Imoveis vendidos" });
      imoveisVendidos.buscarTudo();
    }
  } catch (error) {
    alertStore.error(error);
  }
})

if (props.imovelVendidoId) {
  imoveisVendidos.buscarItem(props.imovelVendidoId);
}

watch(emFoco, (novoValor) => {
  resetForm({ values: novoValor });
});
</script>

<style scoped>
.ativo svg {
  color: var(--roxo);
  background-color: initial;
  margin-left: initial;
}
</style>
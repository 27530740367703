import { defineStore } from 'pinia';
import { useAlertStore } from '@/store/alert.store';
// eslint-disable-next-line no-undef
const baseUrl = process.env.VUE_APP_API_URL;

export const usePagamentoStore = defineStore('pagamento', {
  state: () => ({
    chamadasPendentes: {
      pagamento: false
    },
    erro: null,
  }),
  actions: {
    async enviarPagamento(payload){
      this.chamadasPendentes.pagamento = true;
      const alertStore = useAlertStore();
      try{
        const request = await this.requestS.post(`${baseUrl}/checkout`, payload )
        this.chamadasPendentes.pagamento = false;
        return request
      } catch(erro){
        alertStore.error(erro);
      }
    }
  },
});

<template>
  <div>
    <form
      ref="formRef"
      @submit.prevent="onSubmitFotoImovel"
    >
      <div class="d-flex gap-3 w-100 mb-2">
        <div class="mb-2 w-100">
          <label for="file">Fotos do imóvel</label>
          <input
            id="file"
            name="file"
            type="file"
            class="inputtext mb-2"
            multiple
            @change="onFileChange"
          >
          <div class="d-grid">
            <small>Utilize CTRL para selecionar mais de uma foto.</small>
            <small>Arquivos válidos: JPG, PNG e GIF</small>
          </div>
        </div>
      </div>

      <div class="d-flex flex-row justify-content-center align-items-center gap-3 mt-4">
        <button
          class="btnRoxo"
          :disabled="isSubmitting"
        >
          Salvar
        </button>
        <a
          v-if="emFoco?.all_images?.length"
          class="btnRoxo"
          type="button"
          @click="removeAllImage()"
        >Excluir todos</a>
        <font-awesome-icon
          v-show="isSubmitting"
          icon="spinner"
          class="spinner mt-3"
        />
      </div>
    </form>

    <div
      v-if="emFoco?.all_images?.length"
      class="d-flex flex-wrap gap-3 mt-5"
    >
      <div
        v-for="(imagem, index) in emFoco?.all_images"
        :key="index"
        class="position-relative"
      >
        <img
          :src="imagem.public_path"
          alt="Foto do imóvel"
          class="img-thumbnail"
        >
        <button
          type="button"
          class="btnRoxo position-absolute top-0 end-0 px-3 py-2"
          @click="removeImage(imagem)"
        >
          X
        </button>
        <form @submit.prevent="AtualizarFotoImovel({ id: imagem.id, analiseId: imagem.analise_id })">
          <div>
            <div class="my-2 mt-3">
              <Field
                v-model="imagem.principal"
                :name="`principal-${imagem.id}`"
                type="checkbox"
                :value="true"
                class="inputcheckbox"
              />
              <LabelFromYup
                :name="`principal`"
                :schema="schema"
                class="ps-2 pb-0"
              />
            </div>

            <div class="mb-2">
              <small>Legenda</small>
              <Field
                v-model="imagem.legenda"
                :name="`legenda-${imagem.id}`"
                placeholder="Legenda"
                type="text"
                class="inputtext mb-2"
              />
            </div>

            <div class="mb-2">
              <small>Ordem</small>
              <Field
                v-model="imagem.ordem"
                :name="`ordem-${imagem.id}`"
                placeholder="Ordem"
                type="text"
                class="inputtext mb-2"
              />
            </div>
          </div>
          <div class="d-flex flex-column align-items-center mt-4">
            <button
              class="btnAmarelo"
              :disabled="isSubmitting"
            >
              Salvar
            </button>
            <font-awesome-icon
              v-show="isSubmitting"
              icon="spinner"
              class="spinner mt-3"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { legenda as schema } from '@/consts/formSchema';
import { useAlertStore } from '@/store/alert.store';
import { useAnaliseMercadoStore } from '@/store/analiseMercado.store';
import { storeToRefs } from 'pinia';
import { Field, useForm } from 'vee-validate';
import { ref } from 'vue';

const analiseMercadoStore = useAnaliseMercadoStore()
const { analiseId, emFoco } = storeToRefs(analiseMercadoStore);
const { isSubmitting, handleSubmit } = useForm();

const alertStore = useAlertStore();

const formRef = ref(null);
const file = ref([])
const props = defineProps({
  pesquisaInteligenteId: {
    type: Number,
    default: 0,
  },
});

async function onFileChange(e) {
  const files = e.target.files;
  if (files.length) {
    file.value = [...files];
  }
}


async function removeImage(index) {
  try {
    await analiseMercadoStore.deletarFoto(index.analise_id, index.id)
    await analiseMercadoStore.buscarItem(index.analise_id);
    alertStore.success('Sucesso');
  } catch (error) {
    alertStore.error(error);
  }
}

const removeAllImage = handleSubmit(async () => {
  let imagens = emFoco.value.all_images;
  if (imagens.length > 0) {
    let analise_id = 0;
    for (let index = 0; index < imagens.length; index++) {
      const imagem = imagens[index];
      analise_id = imagem.analise_id;
      const id = imagem.id;
      await analiseMercadoStore.deletarFoto(analise_id, id)
    }
    await analiseMercadoStore.buscarItem(analise_id);
    alertStore.success('Sucesso');
  }
})

const onSubmitFotoImovel = handleSubmit(async () => {
  if (file.value.length === 0) {
    alertStore.error("Por favor, adicione pelo menos uma foto.");
    return;
  }

  const formData = new FormData();
  file.value.forEach((file) => {
    formData.append('file[]', file);
  });

  try {
    const resposta = await analiseMercadoStore.anexarFoto(analiseId.value, formData)
    if (resposta) {
      formRef.value.reset();
      file.value = [];
      alertStore.success('Sucesso');
      await analiseMercadoStore.buscarItem(analiseId.value);
    }
    alertStore.success('Sucesso');
  } catch (error) {
    alertStore.error("Erro ao enviar as fotos");
  }
})

const AtualizarFotoImovel = handleSubmit(async (values, imagem) => {
  const imagemId = imagem.evt.id;
  const analiseId = imagem.evt.analiseId;
  const legenda = values[`legenda-${imagemId}`]
  const principal = values[`principal-${imagemId}`] || false
  const ordem = values[`ordem-${imagemId}`]

  try {
    const response = await analiseMercadoStore.atualizarFoto(imagemId, analiseId, { legenda, principal, ordem })
    console.log(response);
    if (response != 'Já existe uma imagem principal para esta análise.') {
      console.log('aqui');
      await analiseMercadoStore.buscarItem(analiseId);
      alertStore.success('Sucesso');
    }
  } catch (error) {
    alertStore.error(error);
  }
})

</script>

<style scoped>
.ativo svg {
  color: var(--roxo);
  background-color: initial;
  margin-left: initial;
}

.img-thumbnail {
  padding: .25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 8px;
  max-width: 356px;
  object-fit: cover;
  height: 300px;
}
</style>
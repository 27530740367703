<template>
  <div>
    <form @submit.prevent="onSubmitDetalheAnalise">
      <div>
        <label>Descrição do Imóvel</label>
        <QuillEditor id="imovel_descricao" v-model:content="imovel_descricao" content-type="html" theme="snow"
          class="mb-3" name="imovel_descricao" />
      </div>

      <div class="d-flex align-items-center mb-3">
        <Field v-model="houve_vistoria" name="houve_vistoria" type="checkbox" :value="true" class="inputcheckbox" />
        <LabelFromYup name="houve_vistoria" :schema="schema" class="ps-2 pb-0" />
        <CTooltip placement="top" content="Marque caso o imóvel tenha passado por vistoria.">
          <template #toggler="{ id, on }">
            <font-awesome-icon :aria-describedby="id" icon="circle-info" v-on="on" />
          </template>
        </CTooltip>
      </div>

      <div v-if="houve_vistoria">
        <div class="d-flex align-items-center gap-3 mb-3">
          <div class="mb-3 w-100">
            <LabelFromYup name="solicitante" :schema="schema" />
            <Field name="solicitante" type="text" placeholder="solicitante" class="inputtext" />
          </div>

          <div class="mb-3 w-100">
            <LabelFromYup name="data_avaliacao" :schema="schema" />
            <Field name="data_avaliacao" type="date" class="inputtext" maxlength="10" />
          </div>

          <div class="mb-3 w-100">
            <LabelFromYup name="data_vistoria" :schema="schema" />
            <Field name="data_vistoria" type="date" class="inputtext" maxlength="10" />
          </div>
        </div>

        <div>
          <label>Descrição Vistoria</label>
          <QuillEditor id="descricao_vistoria" v-model:content="descricao_vistoria" content-type="html" theme="snow"
            class="mb-3" name="descricao_vistoria" />
        </div>
      </div>

      <div>
        <div class="d-flex align-items-center gap-1">
          <label>
            Considerações Finais
            <CTooltip placement="top" content="Aqui o corretor ou avaliador, vai falar sobre o imóvel, sobre os valores em que chegou e 
              sobre o mercado onde esta atuando, podendo ainda, dar um valor diferente daquele em que a sua analise chegou, 
              pois aqui é onde o profissional fala do seu mercado.">
              <template #toggler="{ id, on }">
                <font-awesome-icon :aria-describedby="id" icon="circle-info" class="pb-0" v-on="on" />
              </template>
            </CTooltip>
          </label>
          <span class="required">*</span>
        </div>
        <QuillEditor id="consideracoes_finais" v-model:content="consideracoes_finais" content-type="html" theme="snow"
          class="mb-3" name="consideracoes_finais" />
      </div>

      <div class="d-flex gap-3 w-100">
        <div class="mb-3 w-100">
          <div class="d-flex align-items-center gap-1">
            <LabelFromYup name="finalidade" :schema="schema" />

            <CTooltip placement="top"
              content="Aqui você vai definir se é uma análise para venda, locação, processo judicial, análise de mercado.">
              <template #toggler="{ id, on }">
                <font-awesome-icon :aria-describedby="id" icon="circle-info" v-on="on" />
              </template>
            </CTooltip>
            <span class="required">*</span>
          </div>
          <Field placeholder="Finalidade da Avaliação" name="finalidade" type="text" class="inputtext"
            :class="{ 'error': errors.finalidade }" />
          <ErrorMessage class="error-msg" name="finalidade" />
        </div>

        <div class="mb-3 w-100">
          <div class="d-flex align-items-center">
            <LabelFromYup name="valor_consideracao_corretor" :schema="schema" />
            <CTooltip placement="top"
              content="Aqui o corretor ou avaliador, pode inserir o valor do Imóvel de sua opinião pessoal.">
              <template #toggler="{ id, on }">
                <font-awesome-icon :aria-describedby="id" icon="circle-info" v-on="on" />
              </template>
            </CTooltip>
          </div>
          <FloatMaskInput :value="values.valor_consideracao_corretor" converter-para="string"
            name="valor_consideracao_corretor" type="text" placeholder="Valor divulgação" class="inputtext mb-2" />
        </div>
      </div>

      <div class="d-flex flex-column align-items-center mt-4">
        <button class="btnRoxo" :disabled="isSubmitting">
          salvar e continuar
        </button>
        <font-awesome-icon v-show="isSubmitting" icon="spinner" class="spinner mt-3" />
      </div>
    </form>

    <div ref="imageContainer" class="d-flex flex-wrap gap-3 mt-5 container-swap">
      <div v-for="(imagem, index) in sortedImages" :key="imagem.id" class="position-relative">
        <img :src="imagem.public_path" alt="Foto do imóvel" class="img-thumbnail">
        <button type="button" class="btnRoxo position-absolute top-0 end-0 px-3 py-2" @click="removeImage(index)">
          X
        </button>
        <form @submit.prevent="AtualizarFotoImovel(imagem.id)">
          <div>
            <div class="my-2 mt-3">
              <Field name="principal" type="checkbox" :value="true" class="inputcheckbox" />
              <LabelFromYup name="principal" :schema="schema" class="ps-2 pb-0" />
            </div>

            <div class="mb-2">
              <Field name="legenda" placeholder="Legenda" type="text" class="inputtext mb-2" />
            </div>
          </div>

          <div class="d-flex flex-column align-items-center mt-4">
            <button class="btnAmarelo" :disabled="isSubmitting">
              Salvar
            </button>
            <font-awesome-icon v-show="isSubmitting" icon="spinner" class="spinner mt-3" />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import FloatMaskInput from '@/components/FloatMaskInput.vue';
import { detalhesAnaliseMercado as schema } from '@/consts/formSchema';
import { useAlertStore } from '@/store/alert.store';
import { useAnaliseMercadoStore } from '@/store/analiseMercado.store';
import { storeToRefs } from 'pinia';
import { Field, useForm } from 'vee-validate';
import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const analiseMercadoStore = useAnaliseMercadoStore()
const { analiseId, emFoco } = storeToRefs(analiseMercadoStore);

const alertStore = useAlertStore();
const router = useRouter();

const imovel_descricao = ref('');
const consideracoes_finais = ref('');
const descricao_vistoria = ref('');
const houve_vistoria = ref(false)

const props = defineProps({
  analiseMercadoId: {
    type: Number,
    default: 0,
  },
});

const {
  errors, resetForm, handleSubmit, values, isSubmitting,
} = useForm({
  initialValues: emFoco.value,
  validationSchema: schema,
});

const onSubmitDetalheAnalise = handleSubmit(async (values) => {
  try {
    const msg = 'Dados salvos com sucesso!'
    const payload = {
      ...values,
      imovel_descricao: imovel_descricao.value || emFoco.value.imovel_descricao,
      consideracoes_finais: consideracoes_finais.value || emFoco.value.consideracoes_finais,
      descricao_vistoria: descricao_vistoria.value || emFoco.value.descricao_vistoria,
    };
    const resposta = await analiseMercadoStore.enviarAnaliseDetalhe(analiseId.value, payload)
    if (resposta) {
      alertStore.success(msg);
      router.push({ name: 'Editar Análise de mercado', params: { analiseMercadoId: analiseId.value } });
    }

  } catch (error) {
    alertStore.error(error);
  }
})

async function iniciar() {
  if (props.analiseMercadoId) {
    await analiseMercadoStore.buscarItem(props.analiseMercadoId);
  }
}
iniciar()

watch(emFoco, (novoValor) => {
  if (novoValor) {
    resetForm({ values: novoValor });
    imovel_descricao.value = novoValor.imovel_descricao || '';
    consideracoes_finais.value = novoValor.consideracoes_finais || '';
    descricao_vistoria.value = novoValor.descricao_vistoria || '';
  }
}, { immediate: true });
</script>

<style scoped>
.ativo svg {
  color: var(--roxo);
  background-color: initial;
  margin-left: initial;
}

.container-swap {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.img-thumbnail {
  padding: .25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 8px;
  max-width: 356px;
  object-fit: cover;
  height: 300px;
}
</style>
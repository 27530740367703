<template>
  <div>
    <div
      v-if="infoImovel"
      class="py-3 px-3 bordaAmarela mb-3"
    >
      <p>{{ infoImovel.tipo_imovel }}</p>
      <p>{{ infoImovel.endereco }}</p>
      <p v-if="infoImovel.area_construida">
        Área Total: {{ infoImovel.area_total }}m2
      </p>
    </div>
    <div>
      <strong style="color: var(--roxo)">
        Fator de oferta
      </strong>
      <p class="py-2">
        * Os valores constantes nos imóveis em divulgação, são normalmente superiores ao
        valor real do imóvel, por isto torna-se necessário a aplicação de um redutor (FATOR DE OFERTA)
        para adequá-lo ao valor de mercado. Aplicando então o % de redução (FATOR DE OFERTA) ao imóvel divulgado.
      </p>
      <Form
        class="d-flex gap-3 align-items-end mb-4"
        @submit="enviarFatorOferta"
      >
        <div class="w-100">
          <Field
            id="fator_oferta"
            as="select"
            class="inputtext"
            name="fator_oferta"
          >
            <option value="">
              Selecionar
            </option>
            <option
              v-for="item in Object.values(fatorOferta)"
              :key="item.valor"
              :value="item.valor"
            >
              {{ item.nome }}
            </option>
          </Field>
        </div>

        <button class="btnRoxo">
          Aplicar
        </button>
      </Form>
    </div>
    <div
      v-if="listaOrdenada"
      class="tabela mb-3"
    >
      <table class="w-100">
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <col>
        <thead>
          <tr>
            <th />
            <th>Tipo</th>
            <th>Título</th>
            <th
              class="ordenagem"
              @click="sortTable('logradouro' || 'bairro')"
            >
              Endereço/Bairro
            </th>
            <th
              class="ordenagem"
              @click="sortTable('valor_original')"
            >
              Valor
            </th>
            <th
              class="ordenagem"
              @click="sortTable('area')"
            >
              Á. Priv.
            </th>
            <th
              class="ordenagem"
              @click="sortTable('area_total')"
            >
              Á. Total.
            </th>
            <th>R$/m² total</th>
            <th>R$/m² priv</th>
            <th
              class="ordenagem"
              @click="sortTable('anunciante')"
            >
              Anunciante
            </th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in listaOrdenada"
            :key="item.id"
          >
            <td>
              <input
                id="imovel_selecionado"
                type="checkbox"
                name="imovel_selecionado"
                :checked="checkboxStates[item.id] || false"
                @click="selecionaImovel(item.id, $event)"
              >
            </td>
            <td>{{ item.tipo }}</td>
            <td class="titulo">
              {{ item.titulo }}
            </td>
            <td class="endereco">
              {{ item.logradouro || item.bairro }}
            </td>
            <td>R${{ dinheiro(item.valor_original) }}</td>
            <td>{{ item.area_util + `m²` }}</td>
            <td>{{ item.area_total + `m²` }}</td>
            <td>R${{ dinheiro(item.valor_original / item.area_total) }}</td>
            <td>R${{ dinheiro(item.valor_original / item.area_util) }}</td>
            <td class="anunciante">
              {{ item.anunciante }}
            </td>
            <td class="tdBtnAcao">
              <button
                class="icon"
                arial-label="excluir"
                @click="excluirImovel(item.id)"
              >
                <font-awesome-icon icon="trash" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div
      v-if="itensComFatorOferta.length > 0"
      class="pt-3"
    >
      <h2 class="my-3">
        Amostras com Fator de Oferta Aplicado
      </h2>
      <div class="tabela mb-3">
        <table class="w-100">
          <col>
          <col>
          <col>
          <col>
          <col>
          <col>
          <col>
          <col>
          <col>
          <col>
          <thead>
            <tr>
              <th>Tipo</th>
              <th>Título</th>
              <th
                class="ordenagem"
                @click="sortTable('logradouro' || 'bairro')"
              >
                Endereço/Bairro
              </th>
              <th
                class="ordenagem"
                @click="sortTable('fator_oferta')"
              >
                Fator oferta
              </th>
              <th
                class="ordenagem"
                @click="sortTable('valor_imovel')"
              >
                Valor
              </th>
              <th
                class="ordenagem"
                @click="sortTable('area')"
              >
                Á. Priv.
              </th>
              <th
                class="ordenagem"
                @click="sortTable('area_total')"
              >
                Á. Total.
              </th>
              <th>R$/m² total</th>
              <th>R$/m² priv</th>
              <th
                class="ordenagem"
                @click="sortTable('anunciante')"
              >
                Anunciante
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in itensComFatorOferta"
              :key="item.id"
            >
              <td>{{ item.tipo }}</td>
              <td class="titulo">
                {{ item.titulo }}
              </td>
              <td class="endereco">
                {{ item.logradouro || item.bairro }}
              </td>
              <td>{{ item.fator_oferta }}</td>
              <td class="valorImovel">
                R${{ dinheiro(item.valor_imovel) }}
              </td>
              <td>{{ item.area_util + `m²` }}</td>
              <td>{{ item.area_total + `m²` }}</td>
              <td>R${{ dinheiro(item.valor_imovel / item.area_total) }}</td>
              <td>R${{ dinheiro(item.valor_imovel / item.area_util) }}</td>
              <td class="anunciante">
                {{ item.anunciante }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>


    <div>
      <h2 class="my-3">
        Evolução dos cálculos - PTAM
      </h2>
      <div class="py-3 px-3 bordaAmarela mb-3 d-flex gap-3 justify-content-center align-items-center">
        <div>
          <p>Média aritmética =</p>
        </div>
        <div class="calculo">
          <h3>
            {{ dinheiro(evolucaoCalculo?.somaMetroQuadradoAmostras) }}
          </h3>
          <h3>{{ evolucaoCalculo?.countAmostras }}</h3>
        </div>
        <span>=</span>
        <h3>{{ evolucaoCalculo?.mediaMetroQuadrado }}</h3>
      </div>

      <small>Média aritmética = Soma do metro quadrado dividido pelo numero de amostras</small>

      <div class="py-3">
        <div class="d-flex gap-3 justify-content-center py-2">
          <strong>+10%</strong>
          <strong>+10%=R$/m²</strong>
          <div>
            <strong>{{ evolucaoCalculo?.limiteSuperior }}</strong>
            <small>(limite superior)</small>
          </div>
        </div>
        <div class="d-flex mfma py-2">
          <strong>MF = MA</strong>
        </div>
        <div class="d-flex gap-3 justify-content-center py-2">
          <strong>-10%</strong>
          <strong>-10%=R$/m²</strong>
          <div>
            <strong>{{ evolucaoCalculo?.limiteInferior }}</strong>
            <small>(limite inferior)</small>
          </div>
        </div>
      </div>
    </div>

    <div>
      <h2 class="my-3">
        Determinação da Média Final
      </h2>
      <div class="py-3 px-3 bordaAmarela mb-3 d-flex gap-3 justify-content-center align-items-center">
        <div>
          <p>Média aritmética =</p>
        </div>
        <div class="calculo">
          <h3>
            {{ dinheiro(determinacaoCalculo?.mediaFinal) }}
          </h3>
          <h3>{{ determinacaoCalculo?.countAmostras }}</h3>
        </div>
        <span>=</span>
        <h3>{{ determinacaoCalculo?.mediaMetroQuadrado }}</h3>
      </div>
      <small>Média aritmética = Soma do valor do m² das Amostras Restantes dividido pelo número de amostras
        restantes</small>
    </div>

    <div>
      <h2 class="my-3">
        Cálculo Valor Imóvel
      </h2>
      <div class="py-3 px-3 bordaAmarela mb-3">
        <div class="d-flex gap-3 justify-content-center align-items-center">
          <div>
            <p>Valor do Imóvel =</p>
          </div>
          <div class="d-flex gap-2">
            <h3>
              {{ dinheiro(calculoImovel?.areaTotal) }}
            </h3>
            <span>x</span>
            <h3>{{ calculoImovel?.mediaMetroQuadrado }}</h3>
          </div>
          <span>=</span>
          <h3>{{ calculoImovel?.valorImovel }}</h3>
        </div>
        <div class="mt-2 d-flex gap-3 justify-content-center">
          <p>
            Limite Superior
            <strong>{{ calculoImovel?.limiteSuperior }}</strong>
            <small class="ps-2">(variação até o máximo de 5%)</small>
          </p>
          <span>-</span>
          <p>
            Limite Inferior
            <strong>{{ calculoImovel?.limiteInferior }}</strong>
            <small class="ps-2">(variação até o mínimo de -5%)</small>
          </p>
        </div>
      </div>
      <small>Valor do imóvel = Área do imóvel x Média final</small>
    </div>

    <div v-if="calculoAreaConstruida?.vac != '0,00' && infoImovel?.usar_area_construida">
      <h2 class="my-3">
        Determinação do Valor de Área Construída
      </h2>
      <div class="py-3 px-3 bordaAmarela mb-3">
        <div class="d-flex gap-3 justify-content-center align-items-center">
          <div>
            <p>Valor da Área Construída =</p>
          </div>
          <div class="d-flex gap-2">
            <h3>
              {{ dinheiro(calculoAreaConstruida?.areaUtil) }}
            </h3>
            <span>x</span>
            <h3>{{ calculoAreaConstruida?.valorAreaConstruida }}</h3>
          </div>
          <span>=</span>
          <h3>{{ calculoAreaConstruida?.vac }}</h3>
        </div>
      </div>
      <small>Valor da Área Construída (V.A.C) = Área construída (m²) x Valor m² área construída</small>
    </div>

    <div v-if="calculoAreaConstruida2?.vac != '0,00' && infoImovel?.usar_area_construida">
      <h2 class="my-3">
        Determinação do Valor de Área Construída 2
      </h2>
      <div class="py-3 px-3 bordaAmarela mb-3">
        <div class="d-flex gap-3 justify-content-center align-items-center">
          <div>
            <p>Valor da Área Construída =</p>
          </div>
          <div class="d-flex gap-2">
            <h3>
              {{ dinheiro(calculoAreaConstruida2?.areaUtil) }}
            </h3>
            <span>x</span>
            <h3>{{ calculoAreaConstruida2?.valorAreaConstruida }}</h3>
          </div>
          <span>=</span>
          <h3>{{ calculoAreaConstruida2?.vac }}</h3>
        </div>
      </div>
      <small>Valor da Área Construída 2 (V.A.C 2) = Área construída 2 (m²) x Valor m² área construída 2</small>
    </div>


    <div v-if="valorImovelAvaliando != '0,00' && infoImovel?.usar_area_construida">
      <h2 class="my-3">
        Valor do Imóvel Avaliando
      </h2>
      <div class="py-3 px-3 bordaAmarela mb-3">
        <div class="d-flex gap-3 justify-content-center align-items-center">
          <div>
            <p>Valor do Imóvel Avaliando =</p>
          </div>
          <div class="d-flex gap-2">
            <h3>
              {{ valorImovelAvaliando }}
            </h3>
          </div>
        </div>
      </div>
      <small>Valor do Imóvel Avaliando =
        Valor do Imóvel +
        Valor da Área Construída (V.A.C)
        <span v-if="calculoAreaConstruida2?.vac != 0"> + Valor da Área Construída 2 (V.A.C 2) </span></small>
    </div>

    <div class="d-flex flex-column align-items-center mt-4">
      <button
        class="btnRoxo"
        @click="salvarAmostras"
      >
        Salvar e continuar
      </button>
      <font-awesome-icon
        v-show="isSubmitting"
        icon="spinner"
        class="spinner mt-3"
      />
    </div>
  </div>
</template>

<script setup>
import fatorOferta from '@/consts/fatorOferta';
import dinheiro from '@/helpers/dinheiro';
import { useAlertStore } from '@/store/alert.store';
import { usePtamsStore } from '@/store/ptams.store';
import { Tab } from 'bootstrap';
import { storeToRefs } from 'pinia';
import { Field, Form } from 'vee-validate';
import { computed, onMounted, reactive, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

const alertStore = useAlertStore();
const router = useRouter();

const ptamsStore = usePtamsStore()
const {
  infoImovel, imovelId,
  laudoIdGet, evolucaoCalculo,
  determinacaoCalculo, calculoImovel,
  listaCalculo, calculoAreaConstruida,
  calculoAreaConstruida2, valorImovelAvaliando
} = storeToRefs(ptamsStore);

const checkboxStates = reactive({});
const area_min = ref('');
const area_max = ref('');
const valor_min = ref('');
const valor_max = ref('');
const endereco = ref('');
const quartos = ref([])
const garagem = ref([])
const listaOrdenada = ref([]);
const colunaOrdenada = ref(null);
const direcaoOrdenada = ref(null);

const props = defineProps({
  ptamsId: {
    type: Number,
    default: 0,
  },
  laudoId: {
    type: Number,
    default: 0,
  }
});

const itensComFatorOferta = computed(() => {
  return listaOrdenada.value.filter(imovel => imovel.fator_oferta !== null &&
    imovel.fator_oferta !== undefined
  )
})

function selecionaImovel(id, event) {
  checkboxStates[id] = event.target.checked;
}

const amostrasSelecionadas = computed(() => {
  return listaCalculo.value.filter(imovel => checkboxStates[imovel.id]);
});

async function excluirImovel(id) {
  alertStore.confirmAction('Deseja mesmo remover esse item?', async () => {
    if (await ptamsStore.excluirAmostraRecalcular(id)) {
      ptamsStore.buscarLaudo(imovelId.value || props.ptamsId);
      alertStore.success('Imóvel removido.');
    }
  }, 'Remover');
}

const listaFiltrada = computed(() => {
  return listaCalculo.value.filter(item => {
    const areaMatch =
      (!area_min.value || item.area >= area_min.value) &&
      (!area_max.value || item.area <= area_max.value);

    const valorMatch =
      (!valor_min.value || item.valor_original >= parseFloat(valor_min.value.replace(/\D/g, ''))) &&
      (!valor_max.value || item.valor_original <= parseFloat(valor_max.value.replace(/\D/g, '')));

    const valorImovelMatch =
      (!valor_min.value || item.valor_imovel >= parseFloat(valor_min.value.replace(/\D/g, ''))) &&
      (!valor_max.value || item.valor_imovel <= parseFloat(valor_max.value.replace(/\D/g, '')));

    const quartosMatch =
      quartos.value.length === 0 ||
      quartos.value.includes(item.quartos ? item.quartos.toString() : '');

    const garagemMatch =
      garagem.value.length === 0 ||
      garagem.value.includes(item.vagas ? item.vagas.toString() : '');

    const enderecoMatch =
      !endereco.value ||
      item.logradouro?.toLowerCase().includes(endereco.value.toLowerCase()) ||
      item.bairro.toLowerCase().includes(endereco.value.toLowerCase());
    return areaMatch && valorMatch && quartosMatch && garagemMatch && enderecoMatch && valorImovelMatch;
  });
});


watch(listaFiltrada, () => {
  listaOrdenada.value = listaFiltrada.value;
}, { immediate: true });

function sortTable(column) {
  if (colunaOrdenada.value === column) {
    direcaoOrdenada.value = direcaoOrdenada.value === 'asc' ? 'desc' : 'asc';
  } else {
    colunaOrdenada.value = column;
    direcaoOrdenada.value = 'asc';
  }

  listaOrdenada.value = listaFiltrada.value.slice().sort((a, b) => {
    let valueA = a[column];
    let valueB = b[column];

    if (typeof valueA === 'string') {
      valueA = valueA.toUpperCase();
      valueB = valueB.toUpperCase();
    }

    if (direcaoOrdenada.value === 'asc') {
      return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
    } else {
      return valueA < valueB ? 1 : valueA > valueB ? -1 : 0;
    }
  });
}

async function salvarAmostras() {
  try {
    const payload = {
      "imovel_id": imovelId.value || props.ptamsId
    };

    const resposta = await ptamsStore.salvarCalculoCriarLaudo(payload)
    if (resposta) {
      alertStore.success('Sucesso');
      const tabElement = document.getElementById('avaliacao');
      const tabTrigger = new Tab(tabElement);
      tabTrigger.show();
    }
  } catch (error) {
    alertStore.error(error);
  }
}

async function enviarFatorOferta(values) {
  try {
    const payload = {
      ...values,
      "laudo_id": laudoIdGet.value || props.laudoId || null,
      "amostras": [
        ...amostrasSelecionadas.value
      ]
    };
    const response = await ptamsStore.fatorOferta(payload)
    if (response) {
      alertStore.success('Sucesso');
      ptamsStore.buscarLaudo(imovelId.value || props.ptamsId);
    }
  } catch (error) {
    alertStore.error(error);
  }
}

onMounted(() => {
  setTimeout(() => {
    listaCalculo.value = [];
  }, 50);
});
</script>

<style scoped>
.calculo {
  min-width: 200px;
}

.calculo h3:first-child {
  border-bottom: 1px solid;
}

h2 {
  color: var(--roxo)
}

.valorImovel {
  color: var(--roxo);
  font-weight: bold;
}

.mfma {
  border-top: 1px solid var(--cinza);
  border-bottom: 1px solid var(--cinza);
  width: 30%;
  margin: 0 auto;
}

.anunciante,
.endereco,
.titulo {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 175px;
}

.ordenagem:hover::after {
  content: '▼';
  font-size: 12px;
  padding-left: 2px;
}

.tabela {
  max-height: 500px;
  overflow-y: auto;
}

.tabela::-webkit-scrollbar {
  width: 5px;
}

.tabela::-webkit-scrollbar-thumb {
  background-color: var(--roxo);
}

.tabela::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.tabela thead {
  position: sticky;
  top: 0;
  z-index: 10;
}

.infoImovel {
  max-width: 250px;
}

</style>
<template>
  <div>
    <div class="d-flex justify-content-start gap-2 mb-2">
      <router-link
        to="/orcamentos"
        class="btnVoltar"
      >
        <font-awesome-icon icon="circle-left" />
      </router-link>
      <a
        v-if="filePath.length && props.orcamentoId"
        :href="filePath"
        class="btnBranco"
        target="_blank"
      >
        <font-awesome-icon icon="file-pdf" />
        Gerar PDF
      </a>
      <button
        v-if="props.orcamentoId"
        class="btnBranco"
        type="button"
        @click="abrirModalEmailOpiniao"
      >
        <font-awesome-icon icon="envelope" />
        Enviar orçamento por e-mail
      </button>
      <button
        v-if="props.orcamentoId"
        class="btnBranco"
        @click="abrirModalFinalizado(props.orcamentoId)"
      >
        <font-awesome-icon icon="check-to-slot" />
        Enviada/Finalizada
      </button>

      <div
        v-if="mostrarModal"
        class="modal fade show"
        style="display: block; background-color: rgba(0,0,0,0.5);"
        tabindex="-1"
        @click="fecharModalEmailOpiniao"
      >
        <div
          class="modal-dialog modal-dialog-centered"
          @click.stop
        >
          <div class="modal-content">
            <div class="modal-header justify-content-between">
              <h3> Enviar opinião de mercado por e-mail</h3>
              <button
                type="button"
                class="Modalclose"
                aria-label="Close"
                @click="fecharModalEmailOpiniao"
              >
                <font-awesome-icon icon="circle-xmark" />
              </button>
            </div>
            <div class="modal-body">
              <Form
                v-slot="{ errors, isSubmitting }"
                :validation-schema="schemaOrcamentoEmail"
                @submit="enviarOrcamento"
              >
                <div class="mb-4">
                  <LabelFromYup
                    name="email"
                    :schema="schemaOrcamentoEmail"
                    :required="true"
                  />
                  <Field
                    name="email"
                    placeholder="seu_email@provedor.com"
                    type="text"
                    class="inputtext"
                    :class="{ 'error': errors.email }"
                  />
                  <ErrorMessage
                    class="error-msg"
                    name="email"
                  />
                </div>
                <div class="modal-footer py-0">
                  <button
                    class="btnRoxo"
                    :disabled="isSubmitting"
                  >
                    Enviar
                  </button>
                  <font-awesome-icon
                    v-show="isSubmitting"
                    icon="spinner"
                    class="spinner mt-3"
                  />
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="mostrarModalFinalizado"
        class="modal fade show"
        style="display: block; background-color: rgba(0,0,0,0.5);"
        tabindex="-1"
        @click="fecharModalFinalizado"
      >
        <div
          class="modal-dialog modal-dialog-centered"
          @click.stop
        >
          <div class="modal-content">
            <div class="modal-header justify-content-between">
              <h3>Enviada/Finalizada</h3>
              <button
                type="button"
                class="Modalclose"
                aria-label="Close"
                @click="fecharModalFinalizado"
              >
                <font-awesome-icon icon="circle-xmark" />
              </button>
            </div>
            <div class="modal-body">
              <Form
                v-slot="{ errors, isSubmitting }"
                :validation-schema="SchemaFinalizado"
                :initial-values="emFoco"
                @submit="enviarStatus"
              >
                <div class="mb-3 w-100">
                  <LabelFromYup
                    name="status"
                    :schema="SchemaFinalizado"
                    :required="true"
                  />
                  <Field
                    name="status"
                    as="select"
                    class="inputtext"
                    :class="{ 'error': errors.status }"
                  >
                    <option value="">
                      Selecionar
                    </option>
                    <option
                      v-for="item in Object.values(finalizado)"
                      :key="item.valor"
                      :value="item.valor"
                    >
                      {{ item.nome }}
                    </option>
                  </Field>
                  <ErrorMessage
                    name="status"
                    class="error-msg"
                  />
                </div>
                <div class="modal-footer py-0">
                  <button
                    class="btnRoxo"
                    :disabled="isSubmitting"
                  >
                    Enviar
                  </button>
                  <font-awesome-icon
                    v-show="isSubmitting"
                    icon="spinner"
                    class="spinner mt-3"
                  />
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-branco">
      <h1 class="mb-3">
        {{ route?.meta?.título }}
      </h1>
      <form @submit.prevent="onSubmitOrcamento">
        <div class="d-flex gap-3 w-100 mb-2">
          <div class="mb-3 w-100">
            <LabelFromYup
              name="tipo_orcamento"
              :schema="schema"
              :required="true"
            />
            <Field
              name="tipo_orcamento"
              as="select"
              class="inputtext light mb1"
              :class="{ 'error': errors.tipo_orcamento }"
            >
              <option value="">
                Selecionar
              </option>
              <option
                v-for="item in Object.values(tipoOrcamento)"
                :key="item.valor"
                :value="item.valor"
              >
                {{ item.nome }}
              </option>
            </Field>
            <ErrorMessage
              name="tipo_orcamento"
              class="error-msg"
            />
          </div>

          <div class="mb-3 w-100">
            <LabelFromYup
              name="nome_solicitante"
              :schema="schema"
              :required="true"
            />
            <Field
              name="nome_solicitante"
              placeholder="Nome do Solicitante"
              type="text"
              class="inputtext"
              :class="{ 'error': errors.nome_solicitante }"
            />
            <ErrorMessage
              class="error-msg"
              name="nome_solicitante"
            />
          </div>
        </div>

        <div class="d-flex gap-3 w-100 mb-2">
          <div class="mb-3 w-100">
            <LabelFromYup
              name="email_solicitante"
              :schema="schema"
              :required="true"
            />
            <Field
              name="email_solicitante"
              placeholder="seu_email@provedor.com"
              type="text"
              class="inputtext"
              :class="{ 'error': errors.email_solicitante }"
            />
            <ErrorMessage
              class="error-msg"
              name="email_solicitante"
            />
          </div>

          <div class="mb-3 w-100">
            <LabelFromYup
              name="cpf"
              :schema="schema"
              :required="true"
            />
            <Field
              v-maska
              name="cpf"
              type="text"
              placeholder="CPF/CNPJ"
              class="inputtext"
              maxlength="18"
              data-maska="['###.###.###-##', '##.###.###/####-##']"
              :class="{ 'error': errors.cpf }"
            />
            <ErrorMessage
              class="error-msg"
              name="cpf"
            />
          </div>

          <div class="mb-3 w-100">
            <LabelFromYup
              name="telefone_solicitante"
              :schema="schema"
              :required="true"
            />
            <Field
              v-maska
              name="telefone_solicitante"
              type="text"
              placeholder="(00) 00000-0000"
              class="inputtext"
              maxlength="15"
              data-maska="(##) #####-####"
              :class="{ 'error': errors.telefone_solicitante }"
            />
            <ErrorMessage
              class="error-msg"
              name="telefone_solicitante"
            />
          </div>
        </div>

        <div class="d-flex gap-3 w-100 mb-2">
          <div class="mb-2 d-flex align-items-center">
            <div>
              <LabelFromYup
                name="cep"
                :schema="schema"
                :required="true"
              />
              <Field
                v-model="cep"
                v-maska
                name="cep"
                type="text"
                placeholder="CEP"
                class="inputtext"
                maxlength="9"
                data-maska="#####-###"
                :class="{ 'error': errors.cep }"
                @focusout="getCep"
              />
              <ErrorMessage
                class="error-msg"
                name="cep"
              />
            </div>
            <span v-if="chamadasPendentes.emFoco">
              <font-awesome-icon
                icon="spinner"
                class="spinner mt-3"
              />
            </span>
          </div>

          <div class="mb-2 w-100">
            <LabelFromYup
              name="address"
              :schema="schema"
              :required="true"
            />
            <Field
              v-model="lista.logradouro"
              name="address"
              type="text"
              placeholder="Rua"
              class="inputtext"
              :class="{ 'error': errors.address }"
            />
            <ErrorMessage
              class="error-msg"
              name="address"
            />
          </div>
        </div>

        <div class="d-flex gap-3 w-100 mb-2">
          <div class="mb-3 w-75">
            <LabelFromYup
              name="bairro"
              :schema="schema"
              :required="true"
            />
            <Field
              v-model="lista.bairro"
              name="bairro"
              type="text"
              placeholder="Bairro"
              class="inputtext"
              :class="{ 'error': errors.bairro }"
            />
            <ErrorMessage
              class="error-msg"
              name="bairro"
            />
          </div>

          <div class="mb-3 w-100">
            <LabelFromYup
              name="estado"
              :schema="schema"
              :required="true"
            />
            <Field
              v-model="lista.uf"
              name="estado"
              type="text"
              placeholder="Estado"
              class="inputtext"
              :class="{ 'error': errors.estado }"
            />
            <ErrorMessage
              class="error-msg"
              name="estado"
            />
          </div>

          <div class="mb-3 w-100">
            <LabelFromYup
              name="cidade"
              :schema="schema"
              :required="true"
            />
            <Field
              v-model="lista.localidade"
              name="cidade"
              type="text"
              placeholder="cidade"
              class="inputtext"
              :class="{ 'error': errors.cidade }"
            />
            <ErrorMessage
              class="error-msg"
              name="cidade"
            />
          </div>
        </div>

        <div class="mb-3">
          <div>
            <label>
              Do Imóvel para Avaliação
              <span class="required">*</span>
            </label>
            <QuillEditor
              id="info_imovel"
              v-model:content="info_imovel"
              content-type="html"
              name="info_imovel"
              theme="snow"
              class="mb-2"
              @blur="validateObjetivo"
            />
          </div>
          <span
            v-if="objetivoError"
            class="error-msg"
          >
            Obrigatório
          </span>
        </div>

        <div class="mb-3">
          <div>
            <label>Objetivo <span class="required">*</span></label>
            <QuillEditor
              v-model:content="objetivo"
              content-type="html"
              class="mb-2"
              theme="snow"
              @blur="validateObjetivo"
            />
            <span
              v-if="objetivoError"
              class="error-msg"
            >
              Obrigatório
            </span>
          </div>
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup
            name="documentacao_necessaria"
            :schema="schema"
            :required="true"
          />
          <Field
            name="documentacao_necessaria"
            type="text"
            placeholder="Documentos necessários"
            class="inputtext mb-2"
            :class="{ 'error': errors.documentacao_necessaria }"
          />
          <ErrorMessage
            class="error-msg"
            name="documentacao_necessaria"
          />
        </div>

        <div class="d-flex gap-3 w-100">
          <div class="mb-3 w-100">
            <LabelFromYup
              name="valor_honorarios"
              :schema="schema"
              :required="true"
            />
            <FloatMaskInput
              name="valor_honorarios"
              type="text"
              placeholder="Valor dos Honorários"
              :value="values.valor_honorarios"
              class="inputtext mb-2"
              :class="{ 'error': errors.valor_honorarios }"
              converter-para="string"
            />
            <ErrorMessage
              class="error-msg"
              name="valor_honorarios"
            />
          </div>

          <div class="mb-3 w-100">
            <LabelFromYup
              name="forma_pagamento"
              :schema="schema"
              :required="true"
            />
            <Field
              name="forma_pagamento"
              placeholder="Forma de Pagamento"
              type="text"
              class="inputtext"
              :class="{ 'error': errors.forma_pagamento }"
            />
            <ErrorMessage
              class="error-msg"
              name="forma_pagamento"
            />
          </div>
        </div>

        <div class="d-flex gap-3 w-100">
          <div class="mb-3 w-100">
            <LabelFromYup
              name="prazo_execucao"
              :schema="schema"
              :required="true"
            />
            <Field
              name="prazo_execucao"
              placeholder="Prazo de Execução"
              type="text"
              class="inputtext"
              :class="{ 'error': errors.prazo_execucao }"
            />
            <ErrorMessage
              class="error-msg"
              name="prazo_execucao"
            />
          </div>

          <div class="mb-3 w-100">
            <LabelFromYup
              name="validade_proposta"
              :schema="schema"
              :required="true"
            />
            <Field
              name="validade_proposta"
              placeholder="Validade desta Proposta"
              type="text"
              class="inputtext"
              :class="{ 'error': errors.validade_proposta }"
            />
            <ErrorMessage
              class="error-msg"
              name="validade_proposta"
            />
          </div>
        </div>

        <div class="d-flex gap-3 w-100">
          <div class="mb-3 w-100">
            <LabelFromYup
              name="nome_banco"
              :schema="schema"
            />
            <Field
              name="nome_banco"
              placeholder="Banco"
              type="text"
              class="inputtext"
              :class="{ 'error': errors.nome_banco }"
            />
            <ErrorMessage
              class="error-msg"
              name="nome_banco"
            />
          </div>

          <div class="mb-3 w-100">
            <LabelFromYup
              name="conta_pagamento"
              :schema="schema"
            />
            <Field
              name="conta_pagamento"
              placeholder="Conta para pagamento"
              type="text"
              class="inputtext"
              :class="{ 'error': errors.conta_pagamento }"
            />
            <ErrorMessage
              class="error-msg"
              name="conta_pagamento"
            />
          </div>
        </div>

        <div class="d-flex gap-3 w-100">
          <div class="mb-3 w-100">
            <LabelFromYup
              name="agencia_pagamento"
              :schema="schema"
            />
            <Field
              name="agencia_pagamento"
              placeholder="Agência para pagamento"
              type="text"
              class="inputtext"
              :class="{ 'error': errors.agencia_pagamento }"
            />
            <ErrorMessage
              class="error-msg"
              name="agencia_pagamento"
            />
          </div>

          <div class="mb-3 w-100">
            <LabelFromYup
              name="chave_pix"
              :schema="schema"
            />
            <Field
              name="chave_pix"
              placeholder="Chave Pix"
              type="text"
              class="inputtext"
              :class="{ 'error': errors.chave_pix }"
            />
            <ErrorMessage
              class="error-msg"
              name="chave_pix"
            />
          </div>
        </div>

        <div class="mb-3 w-100 mb-2">
          <LabelFromYup
            name="titular"
            :schema="schema"
          />
          <Field
            name="titular"
            placeholder="Titular da conta"
            type="text"
            class="inputtext"
            :class="{ 'error': errors.titular }"
          />
          <ErrorMessage
            class="error-msg"
            name="titular"
          />
        </div>

        <div v-if="!emFoco?.notes">
          <label>Notas</label>
          <QuillEditor
            id="notes"
            v-model:content="notes"
            content-type="html"
            theme="snow"
            class="mb-3"
            name="notes"
          />
        </div>

        <div class="d-flex flex-column align-items-center mt-4">
          <button
            class="btnRoxo"
            :disabled="isSubmitting"
          >
            SALVAR
          </button>
          <font-awesome-icon
            v-show="isSubmitting"
            icon="spinner"
            class="spinner mt-3"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import FloatMaskInput from '@/components/FloatMaskInput.vue';

import {
  novoOrcamento as schema,
  status as SchemaFinalizado,
  email as schemaOrcamentoEmail
} from '@/consts/formSchema';

import finalizado from '@/consts/finalizado';
import tipoOrcamento from '@/consts/tipoOrcamento';

import { useAlertStore } from '@/store/alert.store';
import { useCepStore } from '@/store/buscaCep.store';
import { useOrcamentoStore } from '@/store/orcamento.store';

import { vMaska } from "maska/vue";
import { storeToRefs } from 'pinia';
import { ErrorMessage, Field, Form, useForm } from 'vee-validate';
import { ref, watch } from 'vue';
import {
  useRoute,
  useRouter
} from 'vue-router';

const router = useRouter();
const route = useRoute();
const alertStore = useAlertStore();

const cepGet = useCepStore()
const { lista, chamadasPendentes } = storeToRefs(cepGet);

const orcamentoStore = useOrcamentoStore()
const { emFoco, filePath } = storeToRefs(orcamentoStore)

const objetivo = ref('')
const info_imovel = ref('')
const notes = ref('');
const mostrarModal = ref(false)
const mostrarModalFinalizado = ref(false)
const idSelecionado = ref(null);
const objetivoError = ref(false);
const infoImovelError = ref(false);
const location = ref({
  lat: '',
  lng: ''
})

const props = defineProps({
  orcamentoId: {
    type: Number,
    default: 0,
  },
});

const {
  errors, resetForm, handleSubmit, values, isSubmitting,
} = useForm({
  initialValues: emFoco.value,
  validationSchema: schema,
});

async function getCep(cep) {
  let ceptetste = cep.target.value.replace(/\D/g, '')
  try {
    await cepGet.getCep(ceptetste);
    location.value.lat = cepGet.location.latitude;
    location.value.lng = cepGet.location.longitude;
  } catch (error) {
    alertStore.error(error);
  }
}

const validateObjetivo = () => {
  objetivoError.value = objetivo.value.trim().length === 0 || objetivo.value === '<p><br></p>';
  infoImovelError.value = info_imovel.value.trim().length === 0 || info_imovel.value === '<p><br></p>';
};

function abrirModalEmailOpiniao() {
  mostrarModal.value = true;
  document.body.style.overflow = 'hidden';
}

function fecharModalEmailOpiniao() {
  mostrarModal.value = false;
  document.body.style.overflow = 'auto';
}

function abrirModalFinalizado(id) {
  idSelecionado.value = id;
  mostrarModalFinalizado.value = true;
  document.body.style.overflow = 'hidden';
}

function fecharModalFinalizado() {
  mostrarModalFinalizado.value = false;
  document.body.style.overflow = 'auto';
}

async function enviarOrcamento(email) {
  try {
    if (props.orcamentoId) {
      await orcamentoStore.enviarOpiniaoEmail(props.orcamentoId, email)
      fecharModalEmailOpiniao()
    }

  } catch (erro) {
    alertStore.error(erro);
  }
}

async function enviarStatus(status) {
  try {
    if (props.orcamentoId) {
      await orcamentoStore.enviarStatus(props.orcamentoId, status)
      await orcamentoStore.buscarItem(props.orcamentoId);
      fecharModalFinalizado()
    }
  } catch (erro) {
    alertStore.error(erro);
  }
}

const onSubmitOrcamento = handleSubmit(async (values) => {
  try {
    const msg = props.orcamentoId
      ? 'Dados salvos com sucesso!'
      : 'Item adicionado com sucesso!';

    const payload = {
      ...values,
      objetivo: objetivo.value || emFoco.value.objetivo,
      info_imovel: info_imovel.value || emFoco.value.info_imovel,
      notes: notes.value !== null ? notes.value : '',
      lat: location.value.lat || emFoco.value.lat,
      lng: location.value.lng || emFoco.value.lng,
    };

    const resposta = props.orcamentoId
      ? await orcamentoStore.salvarItem(payload, props.orcamentoId)
      : await orcamentoStore.salvarItem(payload);

    if (resposta) {
      alertStore.success(msg);
      orcamentoStore.$reset();
      router.push({ name: "orcamentos" });
    }

  } catch (error) {
    alertStore.error(error);
  }
})

async function iniciar() {
  if (props.orcamentoId) {
    await orcamentoStore.buscarItem(props.orcamentoId);
    await orcamentoStore.gerarPdf(props.orcamentoId)
  }
}
iniciar()

watch(emFoco, (novoValor) => {
  if (novoValor) {
    resetForm({ values: novoValor });
    notes.value = novoValor.notes || '';
    objetivo.value = novoValor.objetivo || '';
    info_imovel.value = novoValor.info_imovel || '';
  }
}, { immediate: true });
</script>

<style scoped>
.ativo svg {
  color: var(--roxo);
  background-color: initial;
  margin-left: initial;
}

.fa-file-lines {
  color: var(--branco);
  font-size: 68px;
}

.autocomplete-list {
  border: 1px solid #ccc;
  max-height: 200px;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.autocomplete-item {
  padding: 8px;
  cursor: pointer;
}

.autocomplete-item:hover {
  background-color: #eee;
}

.error {
  border: 1px solid red;
}
</style>
<template>
  <div class="card-branco">

    <div class="d-flex gap-3 mb-3 align-items-center">
      <router-link to="/pesquisas" class="btnVoltar">
        <font-awesome-icon icon="circle-left" />
      </router-link>
      <h1>
        {{ route?.meta?.título }}
      </h1>
    </div>

    <form @submit.prevent="onSubmitPesquisa">
      <div class="mb-3">
        <LabelFromYup name="title" :schema="schema" />
        <Field name="title" placeholder="Título" type="text" class="inputtext" :class="{ 'error': errors.title }" />
        <ErrorMessage class="error-msg" name="title" />
      </div>

      <div>
        <label>Descrição <span class="required">*</span></label>
        <QuillEditor id="description" v-model:content="description" content-type="html" theme="snow" class="mb-3"
          name="description" />
      </div>

      <div class="d-flex gap-3 align-items-center">
        <div class="mb-3 w-100">
          <LabelFromYup name="status" :schema="schema" />
          <Field name="status" as="select" class="inputtext" :class="{ 'error': errors.status }">
            <option value="">
              Selecionar
            </option>
            <option v-for="item in Object.values(ativo)" :key="item.valor" :value="item.valor">
              {{ item.nome }}
            </option>
          </Field>
          <ErrorMessage class="error-msg" name="status" />
        </div>
        <div class="mb-3 w-100">
          <LabelFromYup name="required" :schema="schema" />
          <Field v-model="required" name="required" as="select" class="inputtext" :class="{ 'error': errors.required }">
            <option v-for="item in Object.values(trueFalse)" :key="item.valor" :value="item.valor">
              {{ item.nome }}
            </option>
          </Field>
          <ErrorMessage class="error-msg" name="required" />
        </div>
      </div>

      <div class="d-flex flex-column align-items-center mt-4">
        <button class="btnRoxo" :disabled="isSubmitting">
          SALVAR
        </button>
        <font-awesome-icon v-show="isSubmitting" icon="spinner" class="spinner mt-3" />
      </div>
    </form>
    <div v-if="props.pesquisaId">
      <PerguntasCriarEditar :pesquisa-id="props.pesquisaId" />
      <div v-if="listaPergunta.length">
        <ListaPerguntas :pesquisa-id="props.pesquisaId" :lista-pergunta="listaPergunta" />
      </div>
    </div>
  </div>
</template>

<script setup>
import ativo from '@/consts/ativo';
import { pesquisa as schema } from '@/consts/formSchema';
import trueFalse from '@/consts/trueFalse';
import { useAlertStore } from '@/store/alert.store';
import { usePesquisaStore } from '@/store/pesquisas.store';
import { storeToRefs } from 'pinia';
import { ErrorMessage, Field, useForm } from 'vee-validate';
import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import ListaPerguntas from './ListaPerguntas.vue';
import PerguntasCriarEditar from './PerguntasCriarEditar.vue';

const PesquisaStore = usePesquisaStore()
const { pesquisa, listaPergunta, pesquisaId: id } = storeToRefs(PesquisaStore);

const router = useRouter();
const route = useRoute();
const alertStore = useAlertStore();

const description = ref(false)
const required = ref(false)

const props = defineProps({
  pesquisaId: {
    type: Number,
    default: 0
  }
})

const {
  errors, isSubmitting, handleSubmit, values, resetForm
} = useForm({
  validationSchema: schema,
  initialValues: pesquisa
});

const onSubmitPesquisa = handleSubmit(async (values) => {
  try {
    const msg = props.pesquisaId
      ? 'Dados salvos com sucesso!'
      : 'Item adicionado com sucesso!';

    const payload = {
      ...values,
      "description": description.value,
    };

    const resposta = props.pesquisaId
      ? await PesquisaStore.salvarItem(payload, props.pesquisaId)
      : await PesquisaStore.salvarItem(payload);

    if (resposta) {
      alertStore.success(msg);
      router.push({
        name: 'Editar pesquisa',
        params: { pesquisaId: id?.value || props.pesquisaId }
      });
      PesquisaStore.$reset();
      iniciar()
    }
  } catch (error) {
    alertStore.error(error);
  }
})

watch(pesquisa, (novoValor) => {
  if (novoValor) {
    resetForm({ values: novoValor });
    description.value = novoValor.description || '';
  }
}, { immediate: true });

async function iniciar() {
  if (props.pesquisaId) {
    await PesquisaStore.buscarItem(props.pesquisaId)
  }
}
iniciar()
</script>

<style scoped>
.ativo svg {
  color: var(--roxo);
  background-color: initial;
  margin-left: initial;
}
</style>
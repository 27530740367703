<template>
  <div>
    <div v-if="props.clienteId" class="d-flex justify-content-start gap-2 mb-2">
      <button class="btnBranco" type="button" @click="abrirModalUsuarioAdicional">
        <font-awesome-icon icon="envelope" />
        Vincular Usuário adicional
      </button>

      <button class="btnBranco" type="button" @click="abrirModalContaFinanceira">
        <font-awesome-icon icon="envelope" />
        Vincular Conta financeira
      </button>

      <div v-if="mostrarModal" class="modal fade show" style="display: block; background-color: rgba(0,0,0,0.5);"
        tabindex="-1" @click="fecharModalUsuarioAdicional">
        <div class="modal-dialog modal-dialog-centered" @click.stop>
          <div class="modal-content">
            <div class="modal-header justify-content-between">
              <h3>Vinculo de Usuário</h3>
              <button type="button" class="Modalclose" aria-label="Close" @click="fecharModalUsuarioAdicional">
                <font-awesome-icon icon="circle-xmark" />
              </button>
            </div>
            <div class="modal-body">
              <Form v-slot="{ errors, isSubmitting }" :validation-schema="schemaEmail"
                @submit="vincularUsuarioAdicional">
                <div class="mb-4">
                  <LabelFromYup name="email" :schema="schemaEmail" :required="true" />
                  <Field name="email" placeholder="seu_email@provedor.com" type="text" class="inputtext"
                    :class="{ 'error': errors.email }" />
                  <ErrorMessage class="error-msg" name="email" />
                </div>
                <div class="modal-footer py-0">
                  <button class="btnRoxo" :disabled="isSubmitting">
                    Enviar
                  </button>
                  <font-awesome-icon v-show="isSubmitting" icon="spinner" class="spinner mt-3" />
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>

      <div v-if="mostrarModalContaFinanceira" class="modal fade show"
        style="display: block; background-color: rgba(0,0,0,0.5);" tabindex="-1" @click="fecharModalContaFinanceira">
        <div class="modal-dialog modal-dialog-centered" @click.stop>
          <div class="modal-content">
            <div class="modal-header justify-content-between">
              <h3>Vincular conta Financeira</h3>
              <button type="button" class="Modalclose" aria-label="Close" @click="fecharModalContaFinanceira">
                <font-awesome-icon icon="circle-xmark" />
              </button>
            </div>
            <div class="modal-body">
              <Form v-slot="{ errors, isSubmitting }" :validation-schema="schemaEmail"
                @submit="vincularContaFinanceira">
                <div class="mb-4">
                  <LabelFromYup name="email" :schema="schemaEmail" :required="true" />
                  <Field name="email" placeholder="seu_email@provedor.com" type="text" class="inputtext"
                    :class="{ 'error': errors.email }" />
                  <ErrorMessage class="error-msg" name="email" />
                </div>
                <div class="modal-footer py-0">
                  <button class="btnRoxo" :disabled="isSubmitting">
                    Enviar
                  </button>
                  <font-awesome-icon v-show="isSubmitting" icon="spinner" class="spinner mt-3" />
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-branco">
      <router-link to="/clientes" class="btnVoltar">
        <font-awesome-icon icon="circle-left" />
      </router-link>
      <div class="cliente">
        <h1 class="mb-2">
          Dados
        </h1>
        <div class="d-flex gap-5 mb-3">
          <div>
            <ul class="m-0 p-0">
              <div class="d-flex gap-2">
                <li>
                  Nome: <strong>{{ emFoco?.user.name }}</strong>
                </li>
                <a v-if="emFoco?.user.telefone_celular"
                  :href="`https://web.whatsapp.com/send?phone=+55${emFoco?.user.telefone_celular}&text=Contato`">
                  <font-awesome-icon icon="fa-brands fa-whatsapp" />
                </a>
              </div>
              <li>E-mail: <strong>{{ emFoco?.user.email }}</strong></li>
              <li>E-mail financeiro: <strong>{{ emFoco?.user.email_financeiro }}</strong></li>
              <li>Nascimento: <strong>{{ dateToField(emFoco?.user.nascimento) }}</strong></li>
              <li>Data de Cadastro: <strong>{{ dateToField(emFoco?.user.created_at) }}</strong></li>
              <li>CNAI: <strong>{{ emFoco?.user.cnai }}</strong></li>
            </ul>
          </div>
          <div>
            <ul class="m-0 p-0">
              <li>Número CRECI: <strong>{{ emFoco?.user.numero_creci }}</strong></li>
              <li>Último Login: <strong>{{ dateToField(emFoco?.user.last_login) }}</strong></li>
              <li>RG: <strong>{{ emFoco?.user.rg }}</strong></li>
              <li>CRECI: <strong>{{ emFoco?.user.creci }}</strong></li>
            </ul>
          </div>
          <div>
            <ul class="m-0 p-0">
              <li>Complemento: <strong>{{ emFoco?.user.complemento }}</strong></li>
              <li>Bairro: <strong>{{ emFoco?.user.bairro }}</strong></li>
              <li>Cidade: <strong>{{ emFoco?.user.cidade }}</strong></li>
              <li>CEP: <strong>{{ emFoco?.user.cep }}</strong></li>
            </ul>
          </div>
        </div>
      </div>

      <div class="cliente d-flex gap-3 pb-3">
        <PlanoAtual :cliente-id="clienteId" />

        <PorMes :cliente-id="clienteId" />
      </div>

      <UsuarioAdicional :cliente-id="clienteId" />

      <Assinatura :cliente-id="clienteId" />

      <PacoteLista :cliente-id="clienteId" />

      <div class="d-flex gap-3">
        <HistoricoAcessosLista class="w-100" />
        <PagamentosLista class="w-100" :cliente-id="clienteId" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { email as schemaEmail } from '@/consts/formSchema';
import dateToField from '@/helpers/dateToFiel';
import { useAlertStore } from '@/store/alert.store';
import { useClientesStore } from '@/store/clientes.store';
import { useHistoricoAcessosStore } from '@/store/historicoAcessos.store';
import { usePlanoStore } from '@/store/plano.store';
import { storeToRefs } from 'pinia';
import { ErrorMessage, Field, Form } from 'vee-validate';
import { ref } from 'vue';
import Assinatura from './AssinaturaLista.vue';
import HistoricoAcessosLista from './HistoricoAcessosLista.vue';
import PacoteLista from './PacoteLista.vue';
import PagamentosLista from './PagamentosLista.vue';
import PlanoAtual from './PlanoAtual.vue';
import PorMes from './PorMes.vue';
import UsuarioAdicional from './UsuarioAdicional.vue';

const historicoAcessosStore = useHistoricoAcessosStore()
const meusClientesStore = useClientesStore()
const planoStore = usePlanoStore()

const { emFoco } = storeToRefs(meusClientesStore);

const mostrarModal = ref(false)
const mostrarModalContaFinanceira = ref(false)
const alertStore = useAlertStore();
const props = defineProps({
  clienteId: {
    type: Number,
    default: 0,
  },
});

async function vincularUsuarioAdicional(values) {
  try {
    const payload = {
      ...values,
      "parent_id": props.clienteId
    }

    const resposta = await meusClientesStore.vincularContaFilho(payload)

    if (resposta) {
      alertStore.success('Usuário vinculado com sucesso!');
      fecharModalUsuarioAdicional()
    }
  } catch (erro) {
    alertStore.error(erro);
  }
}

async function vincularContaFinanceira(values) {
  try {
    const payload = {
      ...values,
      "user_id": props.clienteId
    }
    const resposta = await meusClientesStore.vincularContaFinanceira(payload)

    if (resposta) {
      alertStore.success('Usuário vinculado com sucesso!');
      fecharModalContaFinanceira()
    }
  } catch (erro) {
    alertStore.error(erro);
  }
}

function abrirModalUsuarioAdicional() {
  mostrarModal.value = true;
  document.body.style.overflow = 'hidden';
}

function fecharModalUsuarioAdicional() {
  mostrarModal.value = false;
  document.body.style.overflow = 'auto';
}

function abrirModalContaFinanceira() {
  mostrarModalContaFinanceira.value = true;
  document.body.style.overflow = 'hidden';
}

function fecharModalContaFinanceira() {
  mostrarModalContaFinanceira.value = false;
  document.body.style.overflow = 'auto';
}

async function iniciar() {
  if (props.clienteId) {
    meusClientesStore.buscarItem(props.clienteId);
    meusClientesStore.buscarAssinaturas(props.clienteId);
    historicoAcessosStore.buscarTudo(props.clienteId);
    meusClientesStore.buscarPacotesAdicionais()
    meusClientesStore.buscarPacotesAdicionais()
    planoStore.buscarTudo()
  }
}
iniciar()
</script>

<style scoped>
.ativo svg {
  color: var(--roxo);
  background-color: initial;
  margin-left: initial;
}

svg.svg-inline--fa.fa-whatsapp {
  color: #25D366;
}

.cliente li {
  list-style: none;
  color: var(--cinzaEscuro);
  text-decoration: none;
  padding-bottom: 7px;
}
</style>
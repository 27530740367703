<template>
  <div>
    <form @submit.prevent="onSubmitFotoImovel" ref="formRef">
      <div class="d-flex gap-3 w-100 mb-2">
        <div class="mb-2 w-100">
          <label for="foto">Fotos do imóvel</label>
          <input id="foto" name="foto" type="file" class="inputtext mb-2" multiple @change="onFotoChange">
          <small>Arquivos válidos: JPG, PNG e GIF</small>
        </div>

        <div class="mb-2 w-100">
          <label for="pdf">Documentos do imóvel</label>
          <input id="pdf" name="pdf" type="file" class="inputtext mb-2" multiple @change="onPdfChange">
          <small>Arquivos válidos: PDF.</small>
        </div>

        <div class="mb-2 w-100">
          <label for="declaracao">Declaração COFECI</label>
          <input id="declaracao" name="declaracao" type="file" class="inputtext mb-2" @change="onDeclaracaoChange">
          <small>Arquivos válidos: PDF.</small>
        </div>
      </div>

      <div class="d-flex flex-column align-items-center mt-4">
        <button class="btnRoxo" :disabled="isSubmitting">
          Salvar
        </button>
        <font-awesome-icon v-show="isSubmitting" icon="spinner" class="spinner mt-3" />
      </div>
    </form>

    <div v-if="infoImovel?.anexos.length" class="mt-4">
      <p>Documentos do imóvel</p>
      <hr class="my-3">
      <div class="d-flex flex-wrap gap-3 mt-2">
        <div v-for="(anexo, index) in infoImovel?.anexos" :key="index" class="position-relative">
          <div>
            <a :href="anexo.public_path" class="btnBranco btnBordaRoxo py-1" target="_blank">
              <font-awesome-icon icon="file-pdf" />
              Baixar pdf {{ getPdfName(anexo.path) }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div v-if="infoImovel?.declaracao_thumbnail" class="mt-4">
      <p>Declaração COFECI</p>
      <hr class="my-3">
      <div>
        <a :href="infoImovel?.declaracao_thumbnail" class="btnBranco btnBordaRoxo py-1 width-max-content"
          target="_blank">
          <font-awesome-icon icon="file-pdf" />
          Baixar pdf {{ getPdfName(infoImovel?.declaracao_thumbnail) }}
        </a>
      </div>
    </div>

    <div v-if="infoImovel?.imagens.length" class="mt-4">
      <p>Imagens</p>
      <hr class="my-3">
      <div class="">
        <button class="btnRoxo" @click="removeAllImage(infoImovel?.imagens)">
          Excluir todos
        </button>
        <font-awesome-icon v-show="isSubmitting" icon="spinner" class="spinner mt-3" />
      </div>
      <div class="d-flex flex-wrap gap-3 mt-3">
        <div v-for="(imagem, index) in infoImovel?.imagens" :key="index" class="position-relative">
          <img :src="imagem.public_path" alt="Foto do imóvel" class="img-thumbnail">
          <button type="button" class="btnRoxo position-absolute top-0 end-0 px-3 py-2" @click="removeImage(imagem)">
            X
          </button>
          <form @submit.prevent="AtualizarFotoImovel({ id: imagem.id, imovelId: imagem.imovel_id })">
            <div>
              <div class="my-2 mt-3">
                <Field :name="`principal-${imagem.id}`" type="checkbox" :value="true" class="inputcheckbox"
                  v-model="imagem.principal" />
                <LabelFromYup :name="`principal`" :schema="schema" class="ps-2 pb-0" />
              </div>

              <div class="mb-2">
                <small>Legenda</small>
                <Field :name="`legenda-${imagem.id}`" placeholder="Legenda" type="text" class="inputtext mb-2"
                  v-model="imagem.legenda" />
              </div>

              <div class="mb-2">
                <small>Ordem</small>
                <Field :name="`ordem-${imagem.id}`" placeholder="Ordem" type="text" class="inputtext mb-2"
                  v-model="imagem.ordem" />
              </div>
            </div>
            <div class="d-flex flex-column align-items-center mt-4">
              <button class="btnAmarelo" :disabled="isSubmitting">
                Salvar
              </button>
              <font-awesome-icon v-show="isSubmitting" icon="spinner" class="spinner mt-3" />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { legenda as schema } from '@/consts/formSchema';
import { useAlertStore } from '@/store/alert.store';
import { usePtamsStore } from '@/store/ptams.store';
import { storeToRefs } from 'pinia';
import { Field, useForm } from 'vee-validate';
import { ref } from 'vue';

const alertStore = useAlertStore();

const ptamsStore = usePtamsStore()
const { infoImovel, imovelId } = storeToRefs(ptamsStore);
const { isSubmitting, handleSubmit } = useForm();

const formRef = ref(null);
const foto = ref([])
const pdf = ref([])
const declaracao = ref(null)

const props = defineProps({
  ptamsId: {
    type: Number,
    default: 0,
  },
});

async function onFotoChange(e) {
  const fotos = e.target.files;
  if (fotos.length) {
    foto.value = [...fotos];
  }
}

const onPdfChange = (e) => {
  const pdfs = e.target.files;
  if (pdfs.length) {
    pdf.value = [...pdfs];
  }
};

const onDeclaracaoChange = (event) => {
  declaracao.value = event.target.files[0];
};

function getPdfName(path) {
  if (!path || typeof path !== 'string') return '';
  const segments = path.split('/');
  return segments.pop();
}

async function removeImage(index) {

  const imovelId = index.imovel_id;
  const id = index.id;

  try {
    await ptamsStore.deletarFoto(imovelId, id)
    await ptamsStore.buscarInfoImovel(imovelId || props.ptamsId);
    alertStore.success('Sucesso');
  } catch (error) {
    alertStore.error(error);
  }
}
async function removeAllImage(imagens) {
  isSubmitting.value = true

  if (imagens.length > 0) {
    let imovelId = 0;
    for (let index = 0; index < imagens.length; index++) {
      const imagem = imagens[index];
      imovelId = imagem.imovel_id;
      const id = imagem.id;
      await ptamsStore.deletarFoto(imovelId, id)
    }
    await ptamsStore.buscarInfoImovel(imovelId || props.ptamsId);
    alertStore.success('Sucesso');
  }
  isSubmitting.value = false;

}

const AtualizarFotoImovel = handleSubmit(async (values, imagem) => {
  const imagemId = imagem.evt.id;
  const imovelId = imagem.evt.imovelId;
  const legenda = values[`legenda-${imagemId}`]
  const principal = values[`principal-${imagemId}`] || false
  const ordem = values[`ordem-${imagemId}`]

  try {
    const response = await ptamsStore.atualizarFoto(imagemId, imovelId, { legenda, principal, ordem })
    if (response != 'Já existe uma imagem principal para esta análise.') {
      console.log('aqui');
      ptamsStore.buscarInfoImovel(imovelId || props.ptamsId);
      alertStore.success('Sucesso');
    }
  } catch (error) {
    alertStore.error(error);
  }
})


async function onSubmitFotoImovel() {
  isSubmitting.value = true
  const formData = new FormData();
  if (foto.value) {
    foto.value.forEach((foto) => {
      formData.append('foto[]', foto);
    });
  }

  if (pdf.value) {
    pdf.value.forEach((pdf) => {
      formData.append('pdf[]', pdf);
    });
  }

  if (declaracao.value) {
    formData.append('declaracao', declaracao.value);
  }

  try {
    const resposta = await ptamsStore.anexarArquivosPtams(imovelId.value || props.ptamsId, formData)
    if (resposta) {
      await ptamsStore.buscarInfoImovel(imovelId.value || props.ptamsId);
      alertStore.success('Sucesso');
      formRef.value.reset();
      foto.value = [];
      pdf.value = [];
      declaracao.value = null;
    }
  } catch (error) {
    alertStore.error("Erro ao enviar as fotos");
  }
  finally{
  isSubmitting.value = false
  }
}
</script>

<style scoped>
.ativo svg {
  color: var(--roxo);
  background-color: initial;
  margin-left: initial;
}

.img-thumbnail {
  padding: .25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 8px;
  max-width: 356px;
  object-fit: cover;
  height: 300px;
}

.width-max-content {
  width: max-content;
}
</style>